import React,{Fragment} from 'react';
import man from '../../../assets/images/dashboard/user.png';
import { User, LogOut } from 'react-feather';
import { Link } from 'react-router-dom';

const UserMenu = ({ history }) => {
    
    const logOut = async () => {
        let response = await fetch('/logout', {
            method: 'POST',
            body: JSON.stringify({}),
            headers:{'Content-Type': 'application/json'}});
        try{
            let res = await response.json();
            window.location.replace('/login');
        }
        catch{
            window.location.replace('/login');
        }
    }

    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="media align-items-center">
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src="/imagen_perfil" alt="header-user" />
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li><Link to="/perfil" ><User/>Profile</Link></li>
                    <li><a onClick={logOut}><LogOut />Log out</a></li>
                </ul>
            </li>
        </Fragment>
    );
};


export default UserMenu;