import React, { Fragment, useState, useEffect } from 'react';

const Imprimir = () => {
    return ( 
        <Fragment>
            <h1>Hola</h1>
            <button className="btn btn-outline-primary-2x mb-3" onClick={()=> {
                //ipcRenderer.send()
                console.log('Imprimir');
                const ipcRenderer = window.ipcRenderer;
                ipcRenderer.send('prueba',{prueba:'hola'})
            }}>
                Imprimir
            </button>
        </Fragment>
    );
}
 
export default Imprimir;