import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';

const InfoCard = ({data,setActualizar}) => {
    const [info, setInfo] = useState({})

    useEffect(() => {
      setInfo(data)
    }, [data]);

    function ocultarMostrarInfo(){
        fetch('/p_info', {
            method: 'SUBSCRIBE',
            body: JSON.stringify({id: data.ID_PI}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            console.log(res);
            setActualizar(true);
        })
        .catch(err => console.log('Error: '+err))
    }

    function eliminarInfo(){
        fetch('/p_info', {
            method: 'DELETE',
            body: JSON.stringify({id: data.ID_PI}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            setActualizar(true);
        })
        .catch(err => console.log('Error: '+err))
    }

    const {ID_PI, TITLE_PI, CONTENT_PI, LINK_PI, LINK_BUTTON_NAME_PI,IS_VISIBLE_LINK_PI,FILE_PI,FILE_NAME_PI,IS_VISIBLE_FILE_PI,ORDER_PI,IS_VISIBLE_PI} = info

    return (
        <Fragment>
            <div className="col-md-6 mb-4">
                <div className="cardSpeaker">
                    <div className="cardBody">
                        <p><strong>Title:</strong> {TITLE_PI}</p>
                        <p><strong>Content:</strong> {CONTENT_PI}</p>
                        <p><strong>Link:</strong> {LINK_PI != '' ? LINK_PI : '-' }</p>
                        <p><strong>File:</strong> {FILE_PI != '' ? FILE_PI : '-' }</p>
                    </div>
                    <div className="cardFooter buttonsTicket">
                        <Link to={`/info-edit/${ID_PI}`}><button className="btnTicket edit"><i className="fa-regular fa-pen-to-square"></i> Edit</button></Link>
                        <button className="btnTicket delete" onClick={eliminarInfo}><i className="fa-regular fa-trash-can"></i> Delete</button>
                        {
                            IS_VISIBLE_PI ? 
                            <button className="btnTicket hide" onClick={ocultarMostrarInfo}><i className="fa-regular fa-eye-slash"></i> Hide</button>
                            :<button className="btnTicket unhide" onClick={ocultarMostrarInfo}><i className="fa-regular fa-eye"></i> Unhide</button>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
 
export default InfoCard;