import React, { Fragment, useState, useEffect, Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../common/loaderapp';
import Switch from "react-switch";
import axios from 'axios';

const UploadProgram = () => {
    let navigate = useNavigate();
    const { id, idProgram } = useParams();

    const [load, setLoad] = useState(true)
    const [info, setInfo] = useState({link:'',link_button_name:'', file:'',file_name:'',is_visible_file:true, selectGroup : 1})
    const [key, setKey] = useState(0); 
    const [files, setFiles] = useState(null);
    const [esVisible, setEsVisible] = useState(true)

    const [group_list, setGroup_list] = useState([])

    const {link,link_button_name, file,file_name,is_visible_file, selectGroup} = info

    useEffect(() => {
        console.log('el id', id);

        if(id != 0){
            fetch('/p_upload_program', {
                method: 'PATCH',
                body: JSON.stringify({id}),
                headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then(res => {
				console.log('El INFO',res);
                if(res.length != 0){
                    const { IS_FILE_PPD,NAME_FILE_PPD, LOCATION_FILE_PPD, NAME_LINK_PPD, LINK_PPD, ID_GMD_PPD } = res[0];
                    setInfo({link:LINK_PPD,link_button_name:NAME_LINK_PPD, file:LOCATION_FILE_PPD,file_name:NAME_FILE_PPD,is_visible_file:IS_FILE_PPD, selectGroup : ID_GMD_PPD})
                }
            })
            .catch(err => console.log('Error: '+err))
        }

        fetch('/p_upload_list', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            setGroup_list(res.consultar)
        })
        .catch(err => console.log('Error: '+err))

        setLoad(false);
    }, [])

    function setInfoPractical() {
        fetch('/p_upload_program', {
            method: id != 0 ? 'PUT' : 'POST',
            body: JSON.stringify({...info,id,idProgram}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            console.log('El info',res);
            navigate(-1)
        })
        .catch(err => console.log('Error: '+err))
    }

    const handleFileChange = (event) => {
        console.log('FILE',event.target.files[0]);
        const selectedFile = event.target.files[0];
        setInfo({ ...info, 'file':selectedFile.name})
        setFiles(selectedFile);
    };

    const handleUpload = async () => {
        if(is_visible_file){
            if (files) {
                const formData = new FormData();
                formData.append('file', files);
                try {
                    const response = await axios.post('/uploadFile', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    console.log('File uploaded successfully:', response.data);
                    setInfoPractical();
                } catch (error) {
                console.error('Error uploading file:', error);
                }
            } else {
                console.error('No file selected');
                setInfoPractical();
            }
        }
        else{
            setInfoPractical();
        }

    };

    const actInfo=(e)=>setInfo({ ...info, [e.target.name]:(e.target.value)})
    const actCheck=(e)=>setInfo({ ...info, [e.target.name]:(e.target.checked)})

    return (
        <Fragment>
        {
            load ? 
            <Loader/> :
            <Fragment>
                <div className="col-sm-12 mt-5 mb-5">
                    <div className="row">
                        <div className="col-sm-12 mb-2 mt-3">
                            {/* <Breadcrumb title="President" parent="Inicio" /> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h5>Edit information or Add new information</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-4 d-flex" style={{alignItems: 'center'}}>
                            <strong style={{marginRight: '10px'}}>Type:</strong>
                            <Switch onChange={(e) => setInfo({ ...info, 'is_visible_file':e})} checked={is_visible_file} uncheckedIcon={false} checkedIcon={false} />
                            <label style={{marginBottom: '0px', marginLeft: '10px'}}>
                                <span>{is_visible_file ? 'File' : 'Link'}</span>
                            </label>
                        </div>
                        <div className="col-12 mb-4 d-flex" style={{alignItems: 'center'}}>
                            <strong style={{marginRight: '10px'}}> Is visible: </strong>
                            <Switch onChange={(e) => setEsVisible(e)} checked={esVisible} uncheckedIcon={false} checkedIcon={false} />
                            <label style={{marginBottom: '0px', marginLeft: '10px'}}>
                                <span>{esVisible ? 'Visible' : 'Hidden'}</span>
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        {is_visible_file ?
                        <div className="form-group col-md-12 mb-3">
                            <label className="form-label">File to dowloand:</label>
                            <input className="form-control mb-3" placeholder="Button name" name='file_name' value={file_name} onChange={actInfo}></input>
                            <input key={key} className="form-control mb-3" type="file" id="formFile" onChange={handleFileChange}></input>
                        </div>
                        :
                        <div className="form-group col-md-12 mb-3">
                            <label>Link:</label>
                            <input className="form-control mb-3" placeholder="Link" value={link} onChange={actInfo} name='link'></input>
                            <input className="form-control mb-3" placeholder="Button name" value={link_button_name} onChange={actInfo} name='link_button_name'></input>
                        </div>
                        }
                        <div className="form-group col-12 mb-3">
                            <label>Select Group:</label>
                            <select className="form-control" name='selectGroup' value={selectGroup} onChange={(e)=>setInfo({ ...info, selectGroup:e.target.value})}>
                                {
                                    group_list.map(x=><option key={x.ID_GMD} value={x.ID_GMD}>{x.NOMBRE_GRUPO_GMD}</option>)
                                }
                            </select>
                        </div>
                        <div className="col-12">
                            <button type="button" className="btn btn-primary" onClick={handleUpload}>Save changes</button>
                        </div>
                    </div>
                </div>
            </Fragment >
        }
        </Fragment>
    );
}

export default UploadProgram;