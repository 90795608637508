import React, { Fragment, useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../common/loaderapp';
import InfoCard from "./info_card";

const Info = () => {
    const [infoPractical, setInfoPractical] = useState([])
    const [actualizar, setActualizar] = useState(false)
    const [load, setLoad] = useState(true)

    useEffect(() => {
        fetch('/p_info', {headers: {'Content-Type': 'application/json'}})
        .then(res => res.json())
        .then(res => {
            console.log(res);
            setInfoPractical(res)
            setActualizar(false)
            setLoad(false)
        })
        .catch(err => console.log('Error:',err))
    }, [actualizar]);

    return (
        <Fragment>
            {
                load ? 
                <Loader/>
                :
                <div className="col-sm-12 mt-5 mb-5">
                    <div className="row">
                        <div className="col-sm-12 mb-2 mt-3">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h5>Practical info</h5>
                            <p>You can edit the title and content, also you cand add a link or a file to download.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <Link to={`/info-edit/0`}><a className="btnOrange" href='#'>Add new info</a></Link>
                        </div>
                    </div>
                    <div className="row">
                        {
                            infoPractical.map(x=><InfoCard data={x} setActualizar={setActualizar}/>)
                        }
                        {/* <div className="col-md-6 mb-4">
                            <div className="cardSpeaker">
                                <div className="cardBody">
                                    <p><strong>Title:</strong> Visa</p>
                                    <p><strong>Content:</strong> Added</p>
                                    <p><strong>Link:</strong> https://www.thaievisa.go.th/</p>
                                    <p><strong>File:</strong> -</p>
                                </div>
                                <div className="cardFooter buttonsTicket">
                                    <button className="btnTicket edit" data-bs-toggle="modal" data-bs-target="#pracInfoModal"><i className="fa-regular fa-pen-to-square"></i> Edit</button>
                                    <button className="btnTicket delete"><i className="fa-regular fa-trash-can"></i> Delete</button>
                                    <button className="btnTicket hide"><i className="fa-regular fa-eye-slash"></i> Hide</button>
                                    <button className="btnTicket unhide"><i className="fa-regular fa-eye"></i> Unhide</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="cardSpeaker">
                                <div className="cardBody">
                                    <p><strong>Title:</strong> Letter of invitation</p>
                                    <p><strong>Content:</strong> Added</p>
                                    <p><strong>Link:</strong> -</p>
                                    <p><strong>File:</strong> LOI_REQUEST_64th_AGM_BANGKOK_2024.docx</p>
                                </div>
                                <div className="cardFooter buttonsTicket">
                                    <button className="btnTicket edit" data-bs-toggle="modal" data-bs-target="#pracInfoModal"><i className="fa-regular fa-pen-to-square"></i> Edit</button>
                                    <button className="btnTicket delete"><i className="fa-regular fa-trash-can"></i> Delete</button>
                                    <button className="btnTicket hide"><i className="fa-regular fa-eye-slash"></i> Hide</button>
                                    <button className="btnTicket unhide"><i className="fa-regular fa-eye"></i> Unhide</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="cardSpeaker">
                                <div className="cardBody">
                                    <p><strong>Title:</strong> Electricity</p>
                                    <p><strong>Content:</strong> Added</p>
                                    <p><strong>Link:</strong> -</p>
                                    <p><strong>File:</strong> -</p>
                                </div>
                                <div className="cardFooter buttonsTicket">
                                    <button className="btnTicket edit" data-bs-toggle="modal" data-bs-target="#pracInfoModal"><i className="fa-regular fa-pen-to-square"></i> Edit</button>
                                    <button className="btnTicket delete"><i className="fa-regular fa-trash-can"></i> Delete</button>
                                    <button className="btnTicket hide"><i className="fa-regular fa-eye-slash"></i> Hide</button>
                                    <button className="btnTicket unhide"><i className="fa-regular fa-eye"></i> Unhide</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="cardSpeaker">
                                <div className="cardBody">
                                    <p><strong>Title:</strong> Thai Currency</p>
                                    <p><strong>Content:</strong> Added</p>
                                    <p><strong>Link:</strong> -</p>
                                    <p><strong>File:</strong> -</p>
                                </div>
                                <div className="cardFooter buttonsTicket">
                                    <button className="btnTicket edit" data-bs-toggle="modal" data-bs-target="#pracInfoModal"><i className="fa-regular fa-pen-to-square"></i> Edit</button>
                                    <button className="btnTicket delete"><i className="fa-regular fa-trash-can"></i> Delete</button>
                                    <button className="btnTicket hide"><i className="fa-regular fa-eye-slash"></i> Hide</button>
                                    <button className="btnTicket unhide"><i className="fa-regular fa-eye"></i> Unhide</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="cardSpeaker">
                                <div className="cardBody">
                                    <p><strong>Title:</strong> Thai VAT Rates & Tipping</p>
                                    <p><strong>Content:</strong> Added</p>
                                    <p><strong>Link:</strong> -</p>
                                    <p><strong>File:</strong> -</p>
                                </div>
                                <div className="cardFooter buttonsTicket">
                                    <button className="btnTicket edit" data-bs-toggle="modal" data-bs-target="#pracInfoModal"><i className="fa-regular fa-pen-to-square"></i> Edit</button>
                                    <button className="btnTicket delete"><i className="fa-regular fa-trash-can"></i> Delete</button>
                                    <button className="btnTicket hide"><i className="fa-regular fa-eye-slash"></i> Hide</button>
                                    <button className="btnTicket unhide"><i className="fa-regular fa-eye"></i> Unhide</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="cardSpeaker">
                                <div className="cardBody">
                                    <p><strong>Title:</strong> Bangkok Weather</p>
                                    <p><strong>Content:</strong> Added</p>
                                    <p><strong>Link:</strong> -</p>
                                    <p><strong>File:</strong> -</p>
                                </div>
                                <div className="cardFooter buttonsTicket">
                                    <button className="btnTicket edit" data-bs-toggle="modal" data-bs-target="#pracInfoModal"><i className="fa-regular fa-pen-to-square"></i> Edit</button>
                                    <button className="btnTicket delete"><i className="fa-regular fa-trash-can"></i> Delete</button>
                                    <button className="btnTicket hide"><i className="fa-regular fa-eye-slash"></i> Hide</button>
                                    <button className="btnTicket unhide"><i className="fa-regular fa-eye"></i> Unhide</button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            }
        </Fragment >
    );
}

export default Info;