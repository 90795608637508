import React, { useState, useEffect, Fragment } from "react";
import { animations } from "@formkit/drag-and-drop";
import { useDragAndDrop } from "@formkit/drag-and-drop/react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import CardGroup from "./card_group";
import Loader from '../common/loaderapp';
import Switch from "react-switch";

const UploadList = () => {
    const [actualizar, setActualizar] = useState(false)
    const [load, setLoad] = useState(true)
    const [mostrarArchivos, setMostrarArchivos] = useState(false)

    const [programData, setProgramData] = useState({})
    const [listaArchivos, setListaArchivos] = useState([])

    const { idProgram } = useParams();
    
    useEffect(() => {
        fetch('/p_upload_list', {
            headers: {'Content-Type': 'application/json'},
            method:'POST',
            body: JSON.stringify({id: parseInt(idProgram)})
        })
        .then(res => res.json())
        .then(res => {
            console.log(res);

            setProgramData(res[0][0])
            setMostrarArchivos(res[0][0].IS_DOWNLOAD_PDD)

            setListaArchivos(res[1])

            setActualizar(false)
            setLoad(false)
        })
        .catch(err => console.log('Error:',err))
    }, [actualizar]);

    const setVisibility = (e) => {
        fetch('/p_upload_list', {
            headers: {'Content-Type': 'application/json'},
            method:'PUT',
            body: JSON.stringify({id: parseInt(idProgram), visible:e})
        })
        .then(res => res.json())
        .then(res => {
            
        })
        .catch(err => console.log('Error:',err))

        setMostrarArchivos(e)
    }

    return (
        <Fragment>
            {load ? 
            <Loader/>: 
            <Fragment>
                <div className="col-sm-12 mt-5 mb-5">
                    <div className="row">
                        <div className="col-sm-12 mb-2 mt-3"></div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h5>Files: {programData.TITLE_PDD}</h5>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 mb-12">
                            <Link to={`/upload-edit/${idProgram}/0`}>
                                <button className="btnAddSpeaker btnOrange">Add new file</button>
                            </Link>
                        </div>

                        <div className="col-12 my-4 d-flex" style={{alignItems: 'center'}}>
                            <strong style={{marginRight: '10px'}}> Show all files in the app: </strong>
                            <Switch onChange={(e) => setVisibility(e)} checked={mostrarArchivos} uncheckedIcon={false} checkedIcon={false} />
                            {/* <label style={{marginBottom: '0px', marginLeft: '10px'}}>
                                <span>{esVisible ? 'Visible' : 'Hidden'}</span>
                            </label> */}
                        </div>
                    </div>
                    {/* <ItemSpeakers data={group} setActualizar={setActualizar}/> */}
                    <div className="row">
                        { listaArchivos.map(x=><CardGroup key={x.ID_PPD} data={x} setActualizar={setActualizar} idProgram={idProgram}/>) }
                    </div>
                    
                </div>
            </Fragment >
            }
        </Fragment>
    );
}


const ItemSpeakers = ({data,setActualizar}) => {
    const [parent, speakers] = useDragAndDrop(
        data,
        { 
            plugins: [
            animations()] 
        }
    )

    useEffect(() => {
      console.log(speakers.map(x=>x.ID_SPK));
    }, [speakers])
    

    return (
        <div className="row" ref={parent}>
            { speakers.map(x=><CardGroup key={x.ID_SPK} data={x} setActualizar={setActualizar}/>) }
        </div>
    )
}


export default UploadList;