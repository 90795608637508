import React, { Fragment, useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import Select from "react-select";
import { formatoDinero } from "../constant/funcionesGenerales";

const TablaPro = (props) => {

	/* ============== PROPIEDADES ============== */
	const opcionesCantidadResultados = props.resultsOptions || [
		{ value: "10", label: "10" },
		{ value: "20", label: "20" },
		{ value: "30", label: "30" },
		{ value: "40", label: "40" },
	];

	const propRow = props.row? props.row: {}

/* 	const clasesTipos = {
		"0": "pendiente",
		"1": "transferencia",
		"2": "paypal",
		"3": "link",
		"4": "terminal",
		"5": "pendiente",
		"6": "terminal",
	}

	const clasesEstatus = {
		"danger": "cancelado",
		"1": "pendiente",
		"2": "pagado",
		"3": "revisar",
		"4": "pendiente",
		"5": "cortesia",
	}
 */
	const history = useHistory();

	const [loading, setLoading] = useState(props.loading || true);

	const [headers, setHeaders] = useState(props.headers);// props.headers;

	const [rows, setRows] = useState(props.rows || []);

	const [path, setPath] = useState(props.path);
	
	const [method, setMethod] = useState(props.method || 'POST');
	
	// const [body, setBody] = useState(props.body);
	const body = props.body;

	const [totalField] = useState(props.totalField || 'CANTIDAD_RESULTADOS')

	// Paginador
	const [dataPagination, setDataPagination] = useState(props.dataPagination || {
		page: 1,
		pages: [],
		lastPage: 1,
		rowsPerPage: props.rowsPerPage? props.rowsPerPage: 10
	})

	// query params
	const search = useLocation().search;
	const search_params = new URLSearchParams(search);
	const ruta = useLocation().pathname;

	/* ============== USEEFFECT ============== */

	useEffect(() => {
/* 		for (param of temp1.keys()) {
			console.log(param)
		} */
		let pag = getParamsJson(window.location.href)

		let s_pagina			 = pag.pagina? pag.pagina: search_params.get('page'),
			s_filas				 = search_params.get('rowsPerPage');
		
		cargarRegistros(s_pagina || 1, s_filas || dataPagination.rowsPerPage)
	}, [body, props.refresh])

	/* ============== FUNCIONES ============== */

	const selectCantidadResultados = (value) => {
		setDataPagination((s) => ({...s, rowsPerPage: value.value}));
	}

	const construirElementos = () => {	
		return rows.map((fila, i) => // e => elemento, i => index
			<tr key={`key_fila_tabla${i}`} style={{cursor: 'pointer'}}>
			{
				headers.map((h, j) => {
					if(!h.tipo || h.tipo === 'individual')
						return <td key={`key_fila_tabla_td${i}${j}`}
						onClick={(e) => {
							if(propRow.onClick)
								if(!propRow.ignoreColumns.includes(h.texto))
									propRow.onClick(e, fila)
						}}
						>
							{
								h.formatear ? // si se envía este parámetro que es una función, se manda la fila como parámetro al llamarla
									h.formatear(fila)
								:
								h.formato === 'dinero' ?
									`$${formatoDinero(fila[h.texto])}`
									:
									fila[h.texto]
							}
						</td>

					if(!h.tipo || h.tipo === 'normal')
						return <td key={`key_fila_tabla_td${i}${j}`} 
						onClick={(e) => {
							if(propRow.onClick)
								if(!propRow.ignoreColumns.includes(h.texto))
									propRow.onClick(e, fila)
						}}
						>
							<div>
								<strong>{fila[h.titulo]}</strong>
								<br />
								<small>{fila[h.texto]}</small>
							</div>
						</td>

					if(h.tipo === 'categoria')
						return <td key={`key_fila_tabla_td${i}${j}`}
						onClick={(e) => {
							if(propRow.onClick)
								if(!propRow.ignoreColumns.includes(h.texto))
									propRow.onClick(e, fila)
						}}
						>
							<div className={`alert alert-light p-2 m-0 rounded ${h.clases[fila[h.categoria]]}`} role="alert">
								{fila[h.texto]}
							</div>
						</td>

					if(!h.tipo || h.tipo === 'html')
						return <td key={`key_fila_tabla_td${i}${j}`} style={h.style? h.style:{}}
						onClick={(e) => {
							if(propRow.onClick)
								if(!propRow.ignoreColumns.includes(h.texto))
									propRow.onClick(e, fila)
						}}
						>
							{h.html? h.html(fila): ''}
						</td>

					if(h.tipo === 'acciones')
						return <td key={`key_fila_tabla_td${i}${j}`} className="detalle-col text-left"
						onClick={(e) => {
							if(propRow.onClick)
								if(!propRow.ignoreColumns.includes(h.texto))
									propRow.onClick(e, fila)
						}}
						>
						{
							h.acciones.map((a, k) => {
								let objeto;
								if(a.params && Array.isArray(a.params) && a.params.length){
									objeto = {};
									a.params.forEach(param => {
										objeto[param] = fila[param];
									});
								}

								return <button key={`key_fila_tabla_btn${i}${j}${k}`} type="button" className="btn btn-detalle rounded-pill m-1" onClick={a.onClick ? () => {a['onClick'](objeto)} : null}>
									{a['texto']}
								</button>
							})
						}
						</td>
				})
			}
			</tr>
		)
	}

	const construirElementosCargando = () => {
		let arreglo = [], i;
		for(i = 0; i < dataPagination.rowsPerPage; i++){
			arreglo.push(
			<tr key={`key_fila_cargando${i}`}>
			{
				headers.map((h, j) => {
					if(h.tipo === 'categoria')
						return <td key={`key_fila_td_cargando${i}${j}`}>
							<div>
								<div className="skeleton skeleton-text skeleton-footer"></div>
								<div className="skeleton skeleton-text skeleton-text__body"></div>
							</div>
						</td>

					else
						return <td key={`key_fila_td_cargando${i}${j}`} className="detalle-col">
							<div className="skeleton skeleton-text skeleton-badge"></div>
						</td>
				})
			}
			</tr>
			)
		}
		return arreglo;
	}

	const getParamsJson = (url, esUrl) => {
		var arr = url.split('?')? url.split('?'): []
		url = arr[0]
		var strParams = arr[1]
		var arrParams = strParams? strParams.split('&'): []
		var _json = {}
		if(esUrl){
			_json['url'] = url
		}
		arrParams.map((v) => {
			var arrItem = v.split('=')
			_json[arrItem[0]] = arrItem[1]
		})
		return _json
	}
	

	const cargarRegistros = (pagina, n_filas) => {
		// console.log('Cargar registros', JSON.stringify(({...body, pagina, cantidad_resultados: n_filas})));
		// console.log('pagina', pagina);
		// console.log('n_filas', n_filas);

		setLoading(true);

		fetch(path, {
			method,
			body: method === 'GET' ? null : JSON.stringify(({...body, pagina, cantidad_resultados: n_filas})),
			headers: {'Content-Type': 'application/json'}
		}).then(res => res.json())
		.then(res =>  {
			if(res.status === 'OK'){
				if(res.result[0] && res.result[0][totalField]){
					// window.history.pushState({}, 'url', '/mesaregistro?pagina='+pagina)

					let paginacion = infoPaginacion(pagina, res.result[0][totalField], n_filas);
					setDataPagination((s) => ({...s, page: pagina, pages: paginacion.paginas, lastPage: paginacion.total_paginas, rowsPerPage: n_filas}));
					setRows(res.result);
					setLoading(false);
				} else {
					setDataPagination((s) => ({...s, page: 1, pages: [], lastPage: 1, rowsPerPage: n_filas}));
					setRows([]);
					setLoading(false);
				}
			}else{
				setLoading(false);
			}
		}).catch(err => {
			setLoading(false);
			console.log(err);
		})

	}

	return (
	<Fragment>
		<div className="card mt-2">
			{props.paginadorTop?
			<div className="row">
				<div className="col-12 selectnum d-flex align-items-center justify-content-end flex-wrap">
					<div className="pagin">
						<Paginador 
							pagina={dataPagination.page}
							paginas={dataPagination.pages}
							ultima_pagina={dataPagination.lastPage}
							filasPorPagina={dataPagination.rowsPerPage}
							cargarDatos={(pagina, n_filas) => {cargarRegistros(pagina, n_filas)}}
						/>
					</div>
				</div>
			</div>: ''}
			<div className="table-responsive prueba">
				<table className="table table-sticky">
					<thead>
						<tr>
					{
						headers.map((h, i) => {
							return <th key={`key_header	_tabla${i}`} scope="col">
								<div className="tab-header">
									<span className="mb-32">{h.nombre}</span>
								</div>
							</th>
						})
					}
						</tr>
					</thead>
					<tbody>
						{!loading && rows && body ?
							construirElementos()
							:
							construirElementosCargando()
						}
					</tbody>
				</table>
			</div>
		</div>

		<div className="row">
			<div className="col-12 selectnum d-flex align-items-center justify-content-end flex-wrap">
				<div className="select-number d-flex align-items-center mr-3 mb-3">
					<label className="mb-0 mr-2">Elementos por página:</label>

					<Select
						style={{ width: 40 }}
						options={opcionesCantidadResultados}
						placeholder="10"
						onChange={selectCantidadResultados}
						menuPlacement="auto"
					></Select>
				</div>
				<div className="pagin">
					<Paginador 
						pagina={dataPagination.page}
						paginas={dataPagination.pages}
						ultima_pagina={dataPagination.lastPage}
						filasPorPagina={dataPagination.rowsPerPage}
						cargarDatos={(pagina, n_filas) => {cargarRegistros(pagina, n_filas)}}
					/>
				</div>
			</div>
		</div>
	</Fragment>
	)
}

function infoPaginacion (pag, n_resultados /* cantidad de resultados */, n_filas /* filas por página */) {
	pag = parseInt(pag)
	var cantidad = n_filas, array_paginas = []
	var i, fin, total = Math.ceil(n_resultados/cantidad), suma = true, content = ''

	if(pag - 2 < 1){
		i = 1
		fin = 5
	}else{
		i = pag - 2
		fin = pag + 2
	}

	if(fin > total){
		fin = total
	}
	if((fin - i) < 4){
		while((fin - i) < 4 && suma){
			suma = false
			if(i > 1){
				suma = true
				i--
			}
			if((fin - i) < 4 && fin < total){
				suma = true
				fin++
			}
		}
	}

	for(i; i <= fin; i++){
		array_paginas.push(i)
	}

	return {paginas: array_paginas, total_paginas: total}
}

const Paginador = ({pagina, paginas, ultima_pagina, cargarDatos, filasPorPagina}) => {
	return <Pagination aria-label="Page navigation" className="pagination-primary new-pagination">
		
		<PaginationItem onClick={() => {cargarDatos(1, filasPorPagina);}} disabled={pagina == 1 ? true: false}>
			<PaginationLink className="bg-blue arrow">
				<i className="fa fa-angle-double-left"></i>
			</PaginationLink>
		</PaginationItem>
		
		<PaginationItem onClick={() => {cargarDatos(pagina-1, filasPorPagina)}} disabled={pagina == 1 ? true: false}>
			<PaginationLink  className="bg-blue arrow">
				<i className="fa fa-angle-left"></i>
			</PaginationLink>
		</PaginationItem>

		{
			paginas.map((pag, i) => {
				return <PaginationItem key={`PaginationItem${i}`} onClick={() => {cargarDatos(pag, filasPorPagina);}} className={pag == pagina ? "active": ""}>
			<PaginationLink >{pag}</PaginationLink>
		</PaginationItem>
			})
		}

		<PaginationItem onClick={() => {cargarDatos(pagina + 1, filasPorPagina);}} disabled={pagina == ultima_pagina ? true: false}>
			<PaginationLink className="bg-blue arrow">
				<i className="fa fa-angle-right"></i>
			</PaginationLink>
		</PaginationItem>
		
		<PaginationItem onClick={() => {cargarDatos(ultima_pagina, filasPorPagina);}} disabled={pagina == ultima_pagina ? true: false}>
			<PaginationLink className="bg-blue arrow">
				<i className="fa fa-angle-double-right"></i>
			</PaginationLink>
		</PaginationItem>

	</Pagination>
}

export default TablaPro;