import React, { Fragment, useState, useEffect } from "react";
import tools from "../../componentes/tools/tools";
// import TablaPro from "../../componentes/tabla";
import PhoneInput from "../../componentes/react-input-number";
import { useHistory } from "react-router-dom";

// import Select from "react-select";
import Breadcrumb from "../common/breadcrumb";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import notie from 'notie'
import Select from '../../componentes/select'
// import 'react-intl-tel-input/dist/main.css';

const Default = ({
    datos,
    onClickRefresh
}) => {
    const [catalogos, setCatalogos] = useState([])
    const [selects, setSelects] = useState({
        listExpo: {},
        listTipoCarnet: { label: datos.TIPO_CARNET_TEXT, clave: datos.TIPO_CARNET },
        listGenero: { label: datos.GENERO, clave: datos.ID_CG_DP }
    })

    const onClickEnviar = () => {
        const formCarnet = tools.getForm('formCarnet', { claveEtiquetas: `.txtVacios`, ignore: [] })

        if (formCarnet.isEmtyCount) {
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos del carnet no pueden estar vacíos`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        fetch('/rt_mesaregistro_registro', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idRc: datos.ID_RC,
                idRac: datos.ID_RAC,
                listTipoCarnet: selects.listTipoCarnet.clave,
                formCarnet: formCarnet.data
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (!response.estado) {
                    return tools.notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Detalle en el servidor, favor de contactar a soporte técnico`,
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (response.estado && response.existe) {
                    return tools.notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Hay usuarios que ya existen, favor de revisar sus datos)`,
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                // setTimeout(() => window.location.reload('.'), 2000)
                onClickRefresh()
                tools.notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Los datos se guardaron correctamente`,
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            })
            .catch((err) => {
                return tools.notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Detalle en el servidor, favor de contactar a soporte técnico`,
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            })
    }

    useEffect(() => {
        let isS = true;

        fetch('/rt_catalogos_mesa', {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if(!isS){
                    return
                }
                setTimeout((s) => {
                    setSelects((s) => ({
                        ...s,
                        listGenero: { label: datos.GENERO, clave: datos.ID_CG_DP }
                    }))
                }, 100)
                setCatalogos((s) => ({
                    ...s,
                    ...response.datos
                }))
            })

        return () => (isS = false);
    }, []);

    return (
        <div>
            <Fragment>
                <div className="datatable-react mt-4 d-flex justify-content-center">
                    <div className="col-sm-12 ">
                        <div>
                            <div className="row">
                                <div className="col-12">
                                    <form className="col-md-12" id={`formCarnet`}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="carnet-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="pnombre"
                                                                >
                                                                    Fecha Registro:
                                                                </label>
                                                                <span className="pl-2">{datos.FECHA_ALTA_RAC_TEXT}</span>
                                                            </div>
                                                            <div className="form-group d-flex justify-content-start">
                                                            {datos.TIPO_CARNET === 1? 
                                                                <img src="/fullaccess.png" width={80} />
                                                            :datos.TIPO_CARNET === 2?
                                                                <img src="/taller.png" width={80} />
                                                            :datos.TIPO_CARNET === 3? 
                                                                <img src="/expositor.png" width={80} />
                                                            :datos.TIPO_CARNET === 4? 
                                                                <img src="/invitado.png" width={80} />
                                                            :datos.TIPO_CARNET === 5? 
                                                                <img src="/prensa.png" width={80} />
                                                            :datos.TIPO_CARNET === 6? 
                                                                <img src="/staff.png" width={80} />
                                                            :datos.TIPO_CARNET === 7 || datos.TIPO_CARNET === 8? 
                                                                <img src="/solocena.png" width={80} />
                                                            : <h5><span class="badge badge-primary">Carnet {datos.TIPO_CARNET_TEXT}</span></h5>}

                                                            <span className="m-1"></span>
                                                            <Fragment>
                                                            {datos.IMPRIMIO_CARNET_RAC? 
                                                                <h5><span class="badge badge-success">{datos.IMPRIMIO_CARNET_TEXT}</span></h5>
                                                            : 
                                                                <h5><span class="badge badge-warning">{datos.IMPRIMIO_CARNET_TEXT}</span></h5>
                                                            }
                                                            </Fragment>
                                                            <span className="m-1"></span>
                                                            {datos.ESTATUS_DPG === -1? 
                                                                <h5><span class="badge badge-dark">Pago {datos.ESTATUS_DPG_TEXT}</span></h5>
                                                            : datos.ESTATUS_DPG === 0? 
                                                                <h5><span class="badge badge-danger">Pago {datos.ESTATUS_DPG_TEXT}</span></h5>
                                                            : datos.ESTATUS_DPG === 1? 
                                                                <h5><span class="badge badge-light">Pago {datos.ESTATUS_DPG_TEXT}</span></h5>
                                                            : datos.ESTATUS_DPG === 2? 
                                                                <h5><span class="badge badge-success">Pago {datos.ESTATUS_DPG_TEXT}</span></h5>
                                                            : datos.ESTATUS_DPG === 3? 
                                                                <h5><span class="badge badge-warning">Pago {datos.ESTATUS_DPG_TEXT}</span></h5>
                                                            : datos.ESTATUS_DPG === 4? 
                                                                <h5><span class="badge badge-dark">Pago {datos.ESTATUS_DPG_TEXT}</span></h5>
                                                            : datos.ESTATUS_DPG === 5? 
                                                                <h5><span class="badge badge-info">Pago {datos.ESTATUS_DPG_TEXT}</span></h5>
                                                            : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="pnombre"
                                                                >
                                                                    Tipo carnet<span className="text-danger">*</span>
                                                                </label>
                                                                <Select
                                                                    className="form-control form-control-md border border-dark"
                                                                    data-label="Tipo Carnet"
                                                                    placeholder='Seleccionar tipo carnet'
                                                                    id="listTipoCarnet"
                                                                    name="listTipoCarnet"
                                                                    value={selects.listTipoCarnet.clave}
                                                                    datos={catalogos.catTipoCarnets}
                                                                    onChange={({ item }) => {
                                                                        setSelects((s) => ({ ...s, 
                                                                            listTipoCarnet: item
                                                                        }))
                                                                    }}
                                                                />
                                                                <span className={`text-danger txtVacios txtNombre`} style={{ display: 'none' }}>Falta completar (Nombre(s))</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="pnombre"
                                                                >
                                                                    Nombre(s)<span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    className="form-control border border-dark"
                                                                    type="text"
                                                                    defaultValue={datos.NOMBRES_DP}
                                                                    // id={`txtNombre`} 
                                                                    name="txtNombre"
                                                                    placeholder="Nombre(s)"
                                                                />
                                                                <span className={`text-danger txtVacios txtNombre`} style={{ display: 'none' }}>Falta completar (Nombre(s))</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="papellidos"
                                                                >
                                                                    Apellidos<span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    className="form-control border border-dark"
                                                                    defaultValue={datos.APELLIDOS_DP}
                                                                    // id={`txtApellidos`} 
                                                                    name="txtApellidos"
                                                                    type="text"
                                                                    placeholder="Apellidos"
                                                                />
                                                                <span className={`text-danger txtVacios txtApellidos`} style={{ display: 'none' }}>Falta completar Apellidos</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="pcorreo"
                                                                >
                                                                    Correo Electrónico<span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    className="form-control border border-dark"
                                                                    defaultValue={datos.CORREO_DP}
                                                                    // id={`txtCorreo`}
                                                                    name="txtCorreo"
                                                                    type="text"
                                                                    placeholder="Correo Electrónico"
                                                                />
                                                                <span className={`text-danger txtVacios txtCorreo`} style={{ display: 'none' }}>Falta completar Correo Electrónico</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="pcorreo"
                                                                >
                                                                    Género<span className="text-danger">*</span>
                                                                </label>
                                                                <Select
                                                                    className="form-control form-control-md border border-dark"
                                                                    // tipoValue={1}
                                                                    data-label="Género"
                                                                    placeholder="Seleccionar Género"
                                                                    // id={`listGenero`}
                                                                    name="listGenero"
                                                                    datos={catalogos.catGenero}
                                                                    value={selects.listGenero.clave}
                                                                    onChange={({ item }) => {
                                                                        setSelects((s) => ({ ...s, listGenero: item.clave }))
                                                                    }}
                                                                />
                                                                <span className={`text-danger txtVacios listGenero`} style={{ display: 'none' }}>Falta completar Género</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label
                                                                    style={{ fontWeight: 600 }}
                                                                    htmlFor="pcorreo"
                                                                >
                                                                    Whatsapp<span className="text-danger">*</span>
                                                                </label>
                                                                <div className="input-group mb-2">
                                                                    <div className="input-group-prepend border border-dark">
                                                                        <div className="input-group-text"><i className="fa fa-whatsapp" aria-hidden="true"></i></div>
                                                                    </div>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border border-dark"
                                                                        defaultValue={datos.WHATSAPP_DP}
                                                                        // id={`txtWhatsapp`}
                                                                        name="txtWhatsapp"
                                                                        placeholder="Whatsapp"
                                                                    />
                                                                </div>
                                                                <span className={`text-danger txtVacios txtWhatsapp`} style={{ display: 'none' }}>Falta completar Whatsapp</span>
                                                            </div>
                                                        </div>
                                                        {(selects.listTipoCarnet.clave == '3') ?
                                                            <Fragment>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label
                                                                            style={{ fontWeight: 600 }}
                                                                            htmlFor="Puesto"
                                                                        >
                                                                            Puesto<span className="text-danger">*</span>
                                                                        </label>
                                                                        <input
                                                                            className="form-control border border-dark"
                                                                            defaultValue={datos.PUESTO_RAC}
                                                                            // id={`txtPuesto`}
                                                                            name="txtPuesto"
                                                                            type="text"
                                                                            placeholder="Puesto"
                                                                        />
                                                                        <span className={`text-danger txtVacios txtPuesto`} style={{ display: 'none' }}>Falta completar Puesto</span>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                            : ''}
                                                            <div className="col-md-12">
                                                                <div className="row d-flex justify-content-end">
                                                                    <div className="col-md-3">

                                                                            <button type="button"  className="btn btn-primary btn-block mb-4"
                                                                                onClick={() => onClickEnviar()}
                                                                            >Guardar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div>
                </div> */}
            </Fragment>
        </div>
    );
};

export default Default;