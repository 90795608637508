
import React, { Fragment, useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import MySelect from '../common/select'
// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import tools from "../../componentes/tools/tools";
import FormEditarCarnet from './mesa_form_editar'

const Default = ({
    modal,
    setModal,
    setEsRefresh,
    onImprimir
}) => {
    const toggle = () => {
        if (setModal) {
            setModal((s) => ({ ...s, estado: !s.estado }))
        }
    }

    const onClickRefresh = () => {
        setEsRefresh((s) => !s)
    }

    useEffect(() => {
        let isS = true
        return () => isS = false
    }, [])

    return (
        <Fragment>
            <Modal
                className={`modal-body`}
                isOpen={modal.estado}
                toggle={toggle}
                centered={true}
                size={'lg'}
                style={{ maxWidth: '70em', width: '80%', padding: 0 }}
            >
                <ModalHeader toggle={toggle}><div style={{ fontSize: 20 }}>Impresión Carnet</div></ModalHeader>
                <ModalBody>
                    {/* <div className="datatable-react mt-4 d-flex justify-content-center">
                        <div className="col-sm-12 ">
                            <div> */}
                                <div className="row">
                                    <div className="col-12">
                                        <form className="col-md-12" id={`formCarnet`}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="carnet-body">
                                                        {modal.datos.map((v, i) => (<div className="row" key={'imp'+i}>
                                                            <div className="col-md-5" >
                                                                <div className="form-group">
                                                                    <label
                                                                        style={{ fontWeight: 600 }}
                                                                        htmlFor="pnombre"
                                                                    >
                                                                       <span className='mr-2'><strong>{(i + 1)}</strong>.-</span> Nombre(s)<span className="text-danger">*</span>
                                                                    </label>
                                                                    <input
                                                                        className="form-control border border-dark"
                                                                        type="text"
                                                                        // id={`txtNombre`} 
                                                                        name="txtNombre"
                                                                        placeholder="Nombre(s)"
                                                                        value={v.NOMBRES_DP}
                                                                        onChange={(e) => {
                                                                            e.persist()
                                                                            setModal((s) => ({
                                                                                ...s,
                                                                                datos: s.datos.map((k, l) => l === i? ({...k, NOMBRES_DP: e.target.value}): k)
                                                                            }))
                                                                        }}
                                                                    />
                                                                    <span className={`text-danger txtVacios txtNombre`} style={{ display: 'none' }}>Falta completar (Nombre(s))</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="form-group">
                                                                    <label
                                                                        style={{ fontWeight: 600 }}
                                                                        htmlFor="papellidos"
                                                                    >
                                                                        Apellidos<span className="text-danger">*</span>
                                                                    </label>
                                                                    <input
                                                                        className="form-control border border-dark"
                                                                        // defaultValue={datos.APELLIDOS_DP}
                                                                        // id={`txtApellidos`} 
                                                                        name="txtApellidos"
                                                                        type="text"
                                                                        placeholder="Apellidos"
                                                                        value={v.APELLIDOS_DP}
                                                                        onChange={(e) => {
                                                                            e.persist()
                                                                            setModal((s) => ({
                                                                                ...s,
                                                                                datos: s.datos.map((k, l) => l === i? ({...k, APELLIDOS_DP: e.target.value}): k)
                                                                            }))
                                                                        }}
                                                                    />
                                                                    <span className={`text-danger txtVacios txtApellidos`} style={{ display: 'none' }}>Falta completar Apellidos</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 d-flex align-items-end">
                                                                <div className="form-group" onClick={async () => await onImprimir(v)} 
                                                                style={{cursor: 'pointer', backgroundColor: v.IMPRIMIO_CARNET_RAC? 'green': 'gray'}}>
                                                                    <img src='/print.png' width={50} />
                                                                </div>
                                                            </div>
                                                        </div>))}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            {/* </div>
                        </div>
                    </div> */}
                </ModalBody>
                <ModalFooter>
                    {/* <Button color="primary" onClick={() => null}
                    >Guardar</Button> */}
                    <Button color="danger" onClick={toggle}
                    >Cerrar</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default Default