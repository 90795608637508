import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getHistory from 'react-router-global-history';
import '../assets/css/notie.css'
import notie from 'notie'

let locationState={};

function send( dir , data, method , callback){    
    fetch(dir, {
        method,
        body: JSON.stringify(data),
        headers:{'Content-Type': 'application/json'}
    })
    .then((response) => {
        if(response.status === 200){
            return response.json()
        }else if(response.status === 404){
            return response.text()
        }
    })
    .then((response) => {
        callback(response);
    })
    .catch((error) => {
        callback({ estado: 0 });
    })
}

function redirect (path,state){
    if(state)
        locationState[path]=state;
    getHistory().push(path);
}

const toFixed2 = (dato) => {
    if(dato && dato !== ''){
        return dato.toFixed(2)
    }else{
        return '0.00'
    }
}

const useFetch =  (objeto, callback) => {
    // const history = useHistory(); ;charset=utf-8
    if(objeto.method === 'GET'){
        let parametros = '', body = objeto.body? objeto.body: null
        
        if(body){
            Object.keys(body).map((v, i) => {
                if(i === 0){
                    parametros += `?${v}=${body[v]}`
                }else{
                    parametros += `&${v}=${body[v]}`
                }
            })
        }

        fetch(`/${objeto.ruta}${body?parametros:''}`, {
            method: objeto.method,
            headers: {'Content-Type': 'application/json'}
        })
        .then(async(response) => {
            if(response.status === 200){
                let res = await response.json()
                return callback(res);
            }else if(response.status === 404){
                return getHistory().replace('/login')
            }
        })
        .catch((error) => {
            return callback({ estado: 0 });
        })
    }else{
        fetch(`/${objeto.ruta}`, {
            method: objeto.method,
            body: JSON.stringify(objeto.body),
            headers:{'Content-Type': 'application/json'}
        })
        .then(async(response) => {
            if(response.status === 200){
                let res = await response.json()
                return callback(res);
            }else if(response.status === 404){
                return getHistory().replace('/login')
            }
        })
        .catch((error) => {
            return callback({ estado: 0, error });
        })
    }
}

const isNull = (a) => {
    try{
        if(a !== null && a !== undefined && a !== NaN){
            if(Object.keys(a).length){
                return 0
            }else{
                return 1
            }
        } else{
            return 1
        }
    }catch(err){
        return 1
    }
}

const getPaginador = (paginador) => {
    let res = [], k = paginador.p, l = 0, o = paginador.numeros
    
    if(paginador.p + 1 >= paginador.max_mostrar){
        l = paginador.p - Math.floor(paginador.max_mostrar / 2)
        o = paginador.p + Math.ceil(paginador.max_mostrar / 2)
    }

    if(paginador.p  > (paginador.u - paginador.max_mostrar) + 2){
        l = (paginador.ultimo ) - paginador.max_mostrar
        o = paginador.ultimo
    }

    if(!(paginador.ultimo  > paginador.numeros)){
        l = 0
        o = paginador.numeros
    }

    for(let i = l; i < o; i++){
        res.push({ ...paginador, p: i + 1, i: i})
        k++
    }
    
    if(!res.length){
        res.push({p: 1, i: 0})
    }

    return res
}

export default {send, toast, notie , redirect, toFixed2, useFetch, isNull, getPaginador};