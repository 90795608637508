import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import './assets/scss/app.scss';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import SesionContexto, { SesionProvider } from './context/ContextoSesion';

const ComponentePrueba = () => {
	return <React.Fragment>
	  <div>
		<h1>Título de componente</h1>
		<p>Laborum labore tempor fugiat dolore minim. Est commodo velit dolore eiusmod dolor. Pariatur occaecat nisi sint officia proident qui tempor. Sint commodo excepteur ullamco eiusmod eu do. Proident veniam aute proident dolor ipsum sit incididunt. Elit commodo est mollit laborum aute officia laborum dolore ex occaecat. Culpa occaecat aute nulla voluptate.</p>
	  </div>
	</React.Fragment>
  }

const Root = () => {

	return (
		<React.StrictMode>
			<BrowserRouter>
				<SesionProvider>
					<Routes>
						<Route path="/*" element={<App />} />
					</Routes>
				</SesionProvider>
			</BrowserRouter>
		</React.StrictMode>
	)
}

ReactDOM.render(<Root />, document.getElementById('root'));

// import React, { Fragment, useState } from 'react';
// import ReactDOM from 'react-dom';
// import './assets/scss/app.scss';


// import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
// import { ReactRouterGlobalHistory } from 'react-router-global-history';


// // ** Import custom components for redux**
// import App from "./components/app";

// //MAIN SECTIONS

// import Dashboard from './components/dashboard/dashboard';
// import DatosDashboard from './components/datos_dashboard/dashboard';
// import Asientos from './components/evento_dinamico/asientos'
// import Signin from './auth/signin';

// import RegistroConvecion from './components/asistentes/registro-convencion'
// import DetalleConvencion from './components/asistentes/detalle-convencion'
// import Cortesias from './components/asistentes/cortesias'
// import Depositos from './components/depositos/registros-depositos'
// import DetalleDeposito from './components/depositos/detalle-deposito'

// import Asistentes from './components/asistentes/index'
// import Imprimir from './components/pruebas/imprimir'
// // test
// import TablaPrueba from './components/test_tabla'

// import MesaRegistro from './components/mesa_registro/mesa'
// import MesaRegistroNuevo from './components/mesa_registro/mesa_form_alta'

// import RequireAuth from './auth/RequireAuth';

// function Root() {
//     const currentUser = true;

//     return (
//         <div className="App">
//             <BrowserRouter basename={'/'}>
//                 <ReactRouterGlobalHistory />
//                 <Routes>
//                     <Route path={`${process.env.PUBLIC_URL}/datos_dashboard`} component={DatosDashboard}/>
//                     <Route path={`${process.env.PUBLIC_URL}/login`} component={Signin}/>
//                     <Route path={`${process.env.PUBLIC_URL}/asistentes`} component={Asistentes}/>
//                     <Route exact path={`${process.env.PUBLIC_URL}/imprimir`} component={Imprimir}/>
//                     <Route exact path={`${process.env.PUBLIC_URL}/mesaregistro`} component={MesaRegistro}/>
//                     <Route exact path={`${process.env.PUBLIC_URL}/mesaregistro/nuevo`} component={MesaRegistroNuevo}/>
//                     {currentUser !== null ?
//                         <Fragment>

//                             <App>
//                                 {/* dashboard menu */}
//                                 <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
																		
//                                     let ruta_permiso = '';
//                                     let permisos = localStorage.getItem('permisos');
//                                     permisos = JSON.parse(permisos);
//                                     switch(permisos[0].NOMBRE){
//                                         case 'REGISTROS FULL ACCESS': ruta_permiso = 'registros/2/1'; break;
//                                         case 'REGISTROS TALLERES': ruta_permiso = 'registros/2/2'; break;
//                                         case 'DASHBOARD': ruta_permiso = 'dashboard_graficas'; break;
//                                         case 'REPORTE DEPOSITOS': ruta_permiso = 'depositos'; break;
//                                         // case 'ALTA CORTESIAS': menu.push(); break;
//                                         case 'ASIENTOS': ruta_permiso = 'asientos'; break;
//                                     }
//                                     console.log('Navigate a:', ruta_permiso);

//                                     return (<Navigate to={`${process.env.PUBLIC_URL}/${ruta_permiso}`} />)
//                                 }} />
//                                 <Route path={`${process.env.PUBLIC_URL}/inicio`} component={() => <Navigate to={`${process.env.PUBLIC_URL}/`} />} />
//                                 <Route path={`${process.env.PUBLIC_URL}/dashboard_graficas`} component={Dashboard} />
//                                 {/* <Route path={`${process.env.PUBLIC_URL}/registros-convencion`} component={RegistroConvecion} /> */}
//                                 <Route path={`${process.env.PUBLIC_URL}/registros/:evento/:tps`} component={RegistroConvecion} />
//                                 <Route path={`${process.env.PUBLIC_URL}/detalle-convencion/:evento/:tps/:folio`} component={DetalleConvencion} />
//                                 <Route path={`${process.env.PUBLIC_URL}/cortesias`} component={Cortesias} />
//                                 <Route path={`${process.env.PUBLIC_URL}/Asientos`} component={Asientos} />
//                                 <Route path={`${process.env.PUBLIC_URL}/tabla-prueba`} component={TablaPrueba} />
//                                 <Route path={`${process.env.PUBLIC_URL}/depositos`} component={Depositos} />
//                                 <Route exact path={`${process.env.PUBLIC_URL}/detalle-deposito/:id`} component={DetalleDeposito} />
//                             </App>
//                         </Fragment>
//                         :
//                         <Navigate to={`${process.env.PUBLIC_URL}/login`} />
//                     }
//                 </Routes>
//             </BrowserRouter>
//         </div>
//     );
// }