import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';

const CardGroup = ({data,setActualizar,idProgram}) => {
    const [group, setGroup] = useState({})
    useEffect(() => {
      setGroup(data)
    }, [data]);

    function eliminarGroup(){
        fetch('/p_upload_program', {
            method: 'DELETE',
            body: JSON.stringify({id: data.ID_PPD}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            setActualizar(true);
        })
        .catch(err => console.log('Error: '+err))
    }

    return (
    <Fragment>
        <div className="col-md-6 mb-4">
            <div className="cardSpeaker">
                <div className="cardBody">
                    <p><strong>Group name: </strong>{group.IS_FILE_PPD ? group.NAME_FILE_PPD : group.NAME_LINK_PPD}</p>
                    <p><strong>Visible: </strong>{group.ACTIVE_PPD ? "Yes" : "No"}</p>
                </div>
                <div className="cardFooter buttonsTicket">
                    <Link to={`/upload-edit/${idProgram}/${group.ID_PPD}`}><button className="btnTicket edit"><i className="fa-regular fa-pen-to-square"></i> Edit</button></Link>
                    <button className="btnTicket delete" onClick={eliminarGroup}><i className="fa-regular fa-trash-can"></i> Delete</button>
                </div>
            </div>
        </div>
    </Fragment>
    );
}
 
export default CardGroup;