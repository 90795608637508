import React, { Fragment } from 'react';
import Signin from './auth/signin';

import Program from './components/program/program';
import Programedit from './components/programedit/programedit';
import Speakers from './components/speakers/speakers';
import Speakersedit from './components/speakersedit/speakersedit';
import Infop from './components/infop/infop';
import Infopedit from './components/infopedit/infopedit';

import GroupList from './components/gruop_list/group_list';
import GroupListEdit from './components/gruop_list/group_list_members';

import Asistentes from './components/asistentes/index'
import Imprimir from './components/pruebas/imprimir'

import MesaRegistro from './components/mesa_registro/mesa'
import AppLayout from './components/app';
import FormCarnet from './components/mesa_registro/mesa_form_alta';

import { Routes, Route, Navigate } from 'react-router-dom';
import Avisos from './components/avisos/avisos';
import AvisoEdit from './components/avisoedit/avisoedit';

import UploadList from './components/upload_program/upload_list';
import UploadProgram from './components/upload_program/uploadprogram';

const NoAutorizado = () => {
  return <Fragment>
    <div>
      <h1>No tiene los permisos para ver este contenido</h1>
      <p>Laborum labore tempor fugiat dolore minim. Est commodo velit dolore eiusmod dolor. Pariatur occaecat nisi sint officia proident qui tempor. Sint commodo excepteur ullamco eiusmod eu do. Proident veniam aute proident dolor ipsum sit incididunt. Elit commodo est mollit laborum aute officia laborum dolore ex occaecat. Culpa occaecat aute nulla voluptate.</p>
    </div>
  </Fragment>
}

const RutasCMS = () => {
  return (
    <AppLayout>
      <Routes>
        {/* ============== RUTAS INTERNAS DEL CMS ============== */}
        {/* AGM */}

        <Route path='/program' element={<Program/>}/>
        <Route path='/speakers' element={<Speakers/>}/>
        <Route path='/location' element={<Fragment/>}/>
        <Route path='/info' element={<Infop/>}/>
        <Route path='/info-edit/:id' element={<Infopedit/>}/>
        <Route path='/program-edit/:idDay/:idProgram' element={<Programedit/>}/>
        <Route path='/speakers-edit/:id' element={<Speakersedit/>}/>
        <Route path='/avisos' element={<Avisos/>}/>
        <Route path='/aviso-edit/:id' element={<AvisoEdit/>}/>
        <Route path='/group' element={<GroupList/>}/>
        <Route path='/group-edit/:id' element={<GroupListEdit/>}/>

        <Route path='/upload-list/:idProgram' element={<UploadList/>}/>
        <Route path='/upload-edit/:idProgram/:id' element={<UploadProgram/>}/>

        {/* ============== RUTA NO EXISTENTE ============== */}
        <Route path="*" element={<Navigate to={'/program'} />} />
      </Routes>
    </AppLayout>
  )
}

function App() {
  return (
    <Routes>
      {/* ============== RUTAS PÚBLICAS ============== */}
      <Route path={`${process.env.PUBLIC_URL}/login`} element={<Signin />}/>
      <Route path={`${process.env.PUBLIC_URL}/asistentes`} element={<Asistentes />}/>
      <Route path={`${process.env.PUBLIC_URL}/imprimir`} element={<Imprimir />}/>
      <Route path={`${process.env.PUBLIC_URL}/mesaregistro`} element={<MesaRegistro />}/>
      <Route path={`${process.env.PUBLIC_URL}/mesaregistro/nuevo`} element={<FormCarnet esMesaRegistro={true} />}/>
      <Route path={`${process.env.PUBLIC_URL}/mesaregistro/editar/:id`} element={<FormCarnet esMesaRegistro={true} esEditar={1} />}/>
      <Route path={`${process.env.PUBLIC_URL}/no-autorizado`} element={<NoAutorizado />}/>
      {/* ============== RUTAS CON PERMISOS ============== */}
      <Route path='*' element={<RutasCMS />} />
    </Routes>
  );
}

export default App;