import React, { Fragment } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, UserPlus } from 'react-feather';
import Asistentes from './asistentes'
import Talleres from './asistentes_expositores'

const Default = () => {
    return (
        <Fragment>
            {/* <Breadcrumb icon={Truck} title={`Solicitudes/Facturación`} parent={parent}/> */}
            <div className="row theme-tab">
                <Tabs
                    className="col-sm-12"
                // selectedIndex={selectedIndex}
                // onSelect={(index) => { setSelectedIndex(index) }}
                >
                    <TabList className="tabs tab-title d-print-none">
                        <Tab>
                            <User /> Convencionista
                        </Tab>
                        <Tab>
                            <UserPlus /> Expositores/Patrocinador
                        </Tab>
                    </TabList>
                    <div className="tab-content-cls">
                        <TabPanel>
                            <Asistentes/>
                        </TabPanel>
                        <TabPanel>
                            <Talleres/>
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </Fragment>
    )
};

export default Default;