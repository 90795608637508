import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';

const AvisoCard = ({data,setActualizar}) => {
    const [aviso, setAviso] = useState({})

    useEffect(() => {
      setAviso(data)
    }, [data]);

    function eliminarAviso(){
        fetch('/p_avisos', {
            method: 'DELETE',
            body: JSON.stringify({id: data.ID_AN}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            setActualizar(true);
        })
        .catch(err => console.log('Error: '+err))
    }

    const {ID_AN,TITULO_AN,SUBTITULO_AN,DESCRIPCION_AN,FECHA_ALTA_AN,ACTIVO_AN} = aviso

    return (
        <Fragment>
            <div className="col-md-6 mb-4">
                <div className="cardSpeaker">
                    <div className="cardBody">
                        <p><strong>Title:</strong> {TITULO_AN}</p>
                        <p><strong>Subtitle:</strong> {SUBTITULO_AN}</p>
                        <p><strong>Description:</strong> {DESCRIPCION_AN != '' ? DESCRIPCION_AN : '-' }</p>
                    </div>
                    <div className="cardFooter buttonsTicket">
                        <Link to={`/aviso-edit/${ID_AN}`}><button className="btnTicket edit"><i className="fa-regular fa-pen-to-square"></i> Edit</button></Link>
                        <button className="btnTicket delete" onClick={eliminarAviso}><i className="fa-regular fa-trash-can"></i> Delete</button>
                        {/* {
                            IS_VISIBLE_PI ? 
                            <button className="btnTicket hide" onClick={ocultarMostrarInfo}><i className="fa-regular fa-eye-slash"></i> Hide</button>
                            :<button className="btnTicket unhide" onClick={ocultarMostrarInfo}><i className="fa-regular fa-eye"></i> Unhide</button>
                        } */}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
 
export default AvisoCard;