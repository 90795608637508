
import React, {Fragment, useState, useEffect} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import MySelect from '../common/select'
// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import tools from "../../componentes/tools/tools";
import FormEditarCarnet from './mesa_form_editar'

const Default = ({
    modal,
    setModal,
    setEsRefresh
}) => {
    const toggle = () => {
        if(setModal){
            setModal((s) => ({...s, estado: !s.estado}))
        }
    }

    const onClickRefresh = () => {
      setEsRefresh((s) => !s)
    }

    useEffect(() => {
        let isS = true
        return () => isS = false
    }, [])

    return (
    <Fragment>
        <Modal 
            className={`modal-body`}
            isOpen={modal.estado} 
            toggle={toggle} 
            centered={true} 
            size={'lg'}
            style={{maxWidth: '60em', width: '70%', padding: 0}}
            >
          <ModalHeader toggle={toggle}><div style={{fontSize: 20}}>Editar Carnet</div></ModalHeader>
          <ModalBody>
            <FormEditarCarnet 
              datos={modal.datos}
              onClickRefresh={() => onClickRefresh()}
            />
          </ModalBody>
          {/* <ModalFooter>
            <Button color="primary" onClick={() => onClickCancelar()}
            >Guardar</Button>
            <Button color="danger" onClick={toggle}
            >Cerrar</Button>
          </ModalFooter> */}
        </Modal>
      </Fragment>
    )
}

export default Default