import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loader from '../common/loaderapp';

const ProgramEdit = () => {
    let navigate = useNavigate();
    const { idDay, idProgram } = useParams();

    const [programa, setPrograma] = useState({ title:'', start_hour:'01:00:00', end_hour:'01:00:00', room_venue:'', speaker_name:'', extra:'', icon:0, imageMap:0, isVisibleEndHour:true})
    const [horaInicial, setHoraInicial] = useState('01')
    const [minutoInicial, setMinutoInicial] = useState('00')
    const [horaFinal, setHoraFinal] = useState('01')
    const [minutoFinal, setMinutoFinal] = useState('00')
    const [icons, setIcons] = useState([])
    const [imagesMapa, setImagesMapa] = useState([])
    const [selectHora, setSelectHora] = useState(Array.from({ length: 24 }, (_, index) => index + 1))
    const [selectMinutos, setSelectMinutos] = useState(Array.from({ length: 12 }, (_, index) => index * 5))
    const [load, setLoad] = useState(true);

    const { title, start_hour, end_hour, room_venue, speaker_name, extra, icon, imageMap, isVisibleEndHour} = programa

    const actProgram=(e)=>setPrograma({ ...programa, [e.target.name]:(e.target.value)})
    const actCheck=(e)=>setPrograma({ ...programa, [e.target.name]:(e.target.checked)})

    useEffect(() => {
        fetch(`/p_icons`, {headers: {'Content-Type': 'application/json'}})
        .then(res => res.json())
        .then(res => {
            console.log('Iconos',res);
            setIcons(res)
            
        })
        .catch(err => console.log('Error:',err))

        fetch(`/p_images_map`, {headers: {'Content-Type': 'application/json'}})
        .then(res => res.json())
        .then(res => {
            console.log('imagenes map',res);
            setImagesMapa(res)
        })
        .catch(err => console.log('Error:',err))

        if(idProgram != 0){
            fetch('/p_program', {
                method: 'PATCH',
                body: JSON.stringify({id:idProgram}),
                headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then(res => {
				console.log('El program',res);
                if(res.length != 0){
                    const {ID_PDD,ID_PD_PDD,TITLE_PDD,DESCRIPTION_PDD,SPEAKER_PDD,ROOM_VENUE_PDD,START_HOUR_PDD,END_HOUR_PDD,IS_VISIBLE_END_HOUR_PDD,EXTRA_PDD,TEXT_PDD,ID_ICO_PDD,ORDER_PDD,IS_VISIBLE_PDD,START_HOUR,END_HOUR,ID_IMAGE_MAP_PDD} = res[0];
                    let hora_inicio = START_HOUR.split(':')[0]
                    let minuto_inicio = START_HOUR.split(':')[1]

                    setHoraInicial(hora_inicio)
                    setMinutoInicial(minuto_inicio)

                    let hora_fin = END_HOUR.split(':')[0]
                    let minuto_fin = END_HOUR.split(':')[1]

                    setHoraFinal(hora_fin)
                    setMinutoFinal(minuto_fin)

                    setPrograma({ title:TITLE_PDD, start_hour:`${hora_inicio}:${minuto_inicio}:00`, end_hour:`${hora_fin}:${minuto_fin}:00`, room_venue:ROOM_VENUE_PDD, speaker_name:SPEAKER_PDD, extra:EXTRA_PDD, icon:ID_ICO_PDD, imageMap: ID_IMAGE_MAP_PDD ?? 0, isVisibleEndHour:IS_VISIBLE_END_HOUR_PDD})
                }
            })
            .catch(err => console.log('Error: '+err))
        }

        setLoad(false)
        return () => {
        }
    }, [])

    function setInfo() {
        fetch('/p_program', {
            method: idProgram != 0 ? 'PUT' : 'POST',
            body: JSON.stringify({...programa,idDay,idProgram}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            console.log('El speaker',res);
            navigate('/program')
        })
        .catch(err => console.log('Error: '+err))
    }

    function insertarHora(e) {
        switch (e.target.name) {
            case 'horaInicial':
                setHoraInicial(e.target.value)
                setPrograma({ ...programa, start_hour:`${e.target.value}:${minutoInicial}:00`})
                break;
            case 'minutoInicial':
                setMinutoInicial(e.target.value)
                setPrograma({ ...programa, start_hour:`${horaInicial}:${e.target.value}:00`})
                break;
            case 'horaFinal':
                setHoraFinal(e.target.value)
                setPrograma({ ...programa, end_hour:`${e.target.value}:${minutoFinal}:00`})
                break;
            case 'minutoFinal':
                setMinutoFinal(e.target.value)
                setPrograma({ ...programa, end_hour:`${horaFinal}:${e.target.value}:00`})
                break;
        }
    }
    
    return (
        <Fragment>
        {
            load ? 
            <Loader/>
            :<Fragment>
                <div className="col-sm-12 mt-5">
                    <div className="row">
                        <div className="col-sm-12 mb-2 mt-3"></div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h5>Program</h5>
                            <p>You can edit the hour, title, room name, speaker name and add an extra information.</p>
                            <p>Only the filled fields will appear online</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group form-hour col-md-6 mb-3">
                            <label>Initial Hour:</label>
                            <div className="selects">
                                <select className=" select-hour" name={'horaInicial'} value={horaInicial} onChange={insertarHora}>
                                    {
                                        selectHora.map(function (x) {
                                            var hora= `${(x).toString().length == 1 ? '0'+(x).toString() : (x).toString()}`
                                            return <option key={hora} value={hora}>{hora}</option> 
                                        })
                                    }
                                </select>
                                <select className="select-hour" name={'minutoInicial'} value={minutoInicial} onChange={insertarHora}>
                                    {
                                        selectMinutos.map(function (x) {
                                            var hora= `${(x).toString().length == 1 ? '0'+(x).toString() : (x).toString()}`
                                            return <option key={hora} value={hora}>{hora}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-group form-hour col-md-6 mb-3">
                            <label>End Hour: (optional)</label>
                            <div className="selects">
                                <select className=" select-hour" name='horaFinal' value={horaFinal} onChange={insertarHora}>
                                    {
                                        selectHora.map(function (x) {
                                            var hora= `${(x).toString().length == 1 ? '0'+(x).toString() : (x).toString()}`
                                            return <option key={hora} value={hora}>{hora}</option>
                                        })
                                    }
                                </select>
                                <select className=" select-hour" name='minutoFinal' value={minutoFinal} onChange={insertarHora}>
                                    {
                                        selectMinutos.map(function (x) {
                                            var hora= `${(x).toString().length == 1 ? '0'+(x).toString() : (x).toString()}`
                                            return <option key={hora} value={hora}>{hora}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div class="form-check mt-2">
                                <input class="form-check-input" type="checkbox" name='isVisibleEndHour' checked={isVisibleEndHour} onChange={actCheck} ></input>
                                <label class="form-check-label" for="flexCheckDefault">
                                    Show on Website
                                </label>
                            </div>
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <label>Title:</label>
                            <input className="form-control" name='title' value={title} onChange={actProgram}></input>
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <label>Room/Venue:</label>
                            <input className="form-control" name='room_venue' value={room_venue} onChange={actProgram}></input>
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <label>Speaker name:</label>
                            <input className="form-control" name='speaker_name' value={speaker_name} onChange={actProgram}></input>
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <label>Extra:</label>
                            <input className="form-control" name='extra' value={extra} onChange={actProgram}></input>
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <label>Select Icon:</label>
                            <select className="form-control" name='icon' value={icon} onChange={actProgram}>
                                <option key={''} value={''}>{'Select icon'}</option>
                                {
                                    icons.map(x=><option key={x.ID_ICO} value={x.ID_ICO}>{x.NAME_ICO.toUpperCase()}</option>)
                                }
                            </select>
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <label>Select Image Map:</label>
                            <select className="form-control" name='imageMap' value={imageMap} onChange={actProgram}>
                                <option key={''} value={0}>{'None'}</option>
                                {
                                    imagesMapa.map(x=><option key={x.ID_IMAGE_MAP_IM} value={x.ID_IMAGE_MAP_IM}>{x.NAME_MAP_IM.toUpperCase()}</option>)
                                }
                            </select>
                        </div>
                        <div className="col-12 mt-4 d-flex justify-content-between">
                            <button type="button" class="btn btn-primary" onClick={setInfo}>Save changes</button>
                            {idProgram != 0 && <Link to={`/upload-list/`+idProgram}><button type="button" class="btn btn-primary">Files Program</button></Link>}
                        </div>
                    </div>
                </div>
            </Fragment >
        }
        </Fragment>
    );
}

export default ProgramEdit;