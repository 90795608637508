import React, { Fragment, useState, useEffect } from 'react';
import { DropdownToggle, DropdownMenu, DropdownItem ,Dropdown} from 'reactstrap';

const Default = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(props.value? props.value: null);

    const toggle = () => {
        setDropdownOpen((prevState) => !(prevState));
    }

    useEffect(() => {
        let isS = true
        if(isS){
            setSelectedItem(props.value)
        }
        return () => (isS = true)
    }, [props.value])

    return (
        <Dropdown direction={props.direction? props.direction: "left"}  isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color={props.color? props.color: 'gray'} className={props.className? props.className: 'd-flex justify-content-start align-items-center'}>
            {props.title}
        </DropdownToggle>
        <DropdownMenu>
            {props.data.map((v, i) => {
                return (<DropdownItem key={i} 
                    onClick={(e) => {
                    if(props.onClick){
                        props.onClick(v)
                    }
                    setSelectedItem(v)
                }}
                >{v.label}</DropdownItem>)
            })}
            {props.esSinSeleccionar?
            <Fragment>
                <DropdownItem divider />
                <DropdownItem onClick={() => {
                    if(props.onClick){
                        props.onClick(null)
                    }
                    setSelectedItem({ label: props.title })
                }} >Sin Seleccionar</DropdownItem>
            </Fragment>: ''}
        </DropdownMenu>
    </Dropdown>
    )
}

export default Default