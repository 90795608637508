
import React, { Fragment, useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import MySelect from '../common/select'
import tools from '../../js/tools'
import notie from 'notie';
import Select from '../common/select'

const Default = ({
    modal,
    setModal,
    setRefreshTabla
}) => {
   const [datos, setDatos] = useState(modal.datos)
    const [catalogos, setCatalogos] = useState({catCiudad: [], catEstados: []})

    const toggle = () => {
        if (setModal) {
            setDatos((s) => ({}))
            setModal((s) => ({ ...s, estado: !s.estado }))
        }
    }

    const onClickAgregar = () => {
        if(!datos.listTipoCarnet){
            return notie.alert({ type: 'warning', text: 'Falta seleccionar tipo carnet.' })
        }
   
        
        tools.useFetch({
            ruta: 'rt_asistentes_editar',
            method: 'POST',
            body: datos
        }, (response) => {
            setRefreshTabla((s) => !s)
            return notie.alert({ type: 'success', text: 'Carnet editado correctamente' })
        })
    }

    const onSelectEstado = (item) => {
        tools.useFetch({
          ruta: 'rt_catalogos_select',
          method: 'POST',
          body: item
        }, (response) => {
          setCatalogos((s) => ({
            ...s,
            catCiudad: response.datos
          }))
        })
    }

    useEffect(() => {
        let isS = true

        tools.useFetch({
            ruta: "rt_catalogos_df",
            method: "POST",
            body: {},
          }, (response) => {
            if(isS){
              setCatalogos(response.datos)
            }
          });
          
          onSelectEstado({ID_ESTADO: modal.datos.ESTADO_ERC})
          setDatos((s) => ({
              ...s,
              txtNombre: modal.datos.NOMBRES_ECU,
              txtApelidos: modal.datos.APELLIDOS_ECU,
              listTipoCarnet: modal.datos.ID_EC,
              listEstado: modal.datos.ESTADO_ERC,
              listCiudad: modal.datos.CIUDAD_ERC,
              listGenero: modal.datos.ID_GENERO,
              txtCelular: modal.datos.CELULAR_ECU,
              txtCorreo: modal.datos.CORREO_ECU,
              txtComentarios: modal.datos.COMENTARIOS_ERC,
              ID_ECU: modal.datos.ID_ECU,
              ID_ERC: modal.datos.FOLIO_ERC,
              ID_ER: modal.datos.ID_ER
          }))


        return () => isS = false
    }, [modal.datos])

    return (
        <Fragment>
            <Modal
                size="lg" style={{ maxWidth: '1200px', width: '80%', padding: 0 }}
                isOpen={modal.estado}
                toggle={toggle}
            >
                <ModalHeader toggle={toggle}>Nuevo Carnet</ModalHeader>
                <ModalBody>
                    <form className="form-row">
                        
                        {/* <div className={`col-12 mb-2`}><div style={{border: 'solid 0.5px black', width: '100%'}}></div></div> */}
                        <div className={`col-12 mb-2`}>
                            <div className="row">
                                <div className={`col-12 mb-2`}>
                                    <h4>Datos del usuario</h4>
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="form-label">Nombres</label>
                                    <input className="form-control" name="txtNombre" id='txtNombre' type="text" placeholder="Nombres"
                                        value={datos.txtNombre ? datos.txtNombre : ''}
                                        onChange={(v) => {
                                            v.persist();
                                            setDatos((s) => ({ ...s, txtNombre: v.target.value }))
                                        }} />
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="form-label">Apellidos</label>
                                    <div className="form-group">
                                        <input className="form-control" name="txtApelidos" id='txtApelidos' type="text" placeholder="Apellidos"
                                            value={datos.txtApelidos ? datos.txtApelidos : ''}
                                            onChange={(v) => {
                                                v.persist();
                                                setDatos((s) => ({ ...s, txtApelidos: v.target.value }))
                                            }} />
                                    </div>
                                </div>
                                {/* <div className={`col-6 mb-2`}>
                                    <label htmlFor="form-label">Tipo de evento</label>
                                    <Select
                                        className="form-control form-control-md"
                                        data-label="Estado"
                                        placeholder="Seleccionar opción estado"
                                        id="listTipoEvento"
                                        name="listTipoEvento"
                                        // defaultValue={datos.listEstado ? datos.listEstado : ''}
                                        datos={catalogos.catTipoEvento? catalogos.catTipoEvento: []}
                                        onChange={({ item }) => {
                                            setDatos((s) => ({ ...s, listTipoEvento: item.clave }))
                                        }}
                                    />
                                </div> */}
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="form-label">Estado</label>
                                    <Select
                                        className="form-control form-control-md"
                                        data-label="Estado"
                                        placeholder="Seleccionar opción estado"
                                        id="listEstado"
                                        name="listEstado"
                                        // defaultValue={datos.listEstado ? datos.listEstado : ''}
                                        value={datos.listEstado? datos.listEstado: ''}
                                        datos={catalogos.catEstados? catalogos.catEstados: []}
                                        onChange={({ item }) => {
                                            onSelectEstado(item);
                                         
                                            setDatos((s) => ({ ...s, listEstado: item.clave }))
                                        }}
                                    />
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="form-label">Ciudad</label>
                                    <select
                                        className="form-control form-control-md"
                                        data-label="Ciudad"
                                        id="listCiudad"
                                        name="listCiudad"
                                        value={datos.listCiudad ? datos.listCiudad : ''}
                                        onChange={(e) => {
                                            e.persist();
                                            setDatos((s) => ({ ...s, listCiudad: e.target.value }))
                                        }}
                                    >
                                        <option value="0">
                                        Seleccione una ciudad
                                        </option>
                                        {catalogos.catCiudad
                                        ? catalogos.catCiudad.map((v) => (
                                            <option
                                            key={v.ID_CIUDAD + "-" + v.NOMBRE_CIUDAD}
                                            value={v.ID_CIUDAD}
                                            >{v.NOMBRE_CIUDAD}
                                            </option>
                                        ))
                                        : ""}
                                    </select>
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="form-label">Genero</label>
                                    <Select
                                        className="form-control form-control-md"
                                        data-label="Estado"
                                        placeholder="Seleccionar opción estado"
                                        id="listGenero"
                                        name="listGenero"
                                        // defaultValue={datos.listEstado ? datos.listEstado : ''}
                                        value={datos.listGenero? datos.listGenero: ''}
                                        datos={catalogos.catGenero? catalogos.catGenero: []}
                                        onChange={({ item }) => {
                                            setDatos((s) => ({ ...s, listGenero: item.clave }))
                                        }}
                                    />
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="form-label">Tipo Carnet</label>
                                    <Select
                                        className="form-control form-control-md"
                                        data-label="Carnet"
                                        placeholder="Seleccionar opción carnet"
                                        id="listTipoCarnet"
                                        name="listTipoCarnet"
                                        // defaultValue={datos.listEstado ? datos.listEstado : ''}
                                        datos={catalogos.catTipoCarnet? catalogos.catTipoCarnet: []}
                                        value={datos.listTipoCarnet? datos.listTipoCarnet: ''}
                                        onChange={({ item }) => {
                                            setDatos((s) => ({ ...s, listTipoCarnet: item.clave }))
                                        }}
                                    />
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="form-label">Celular</label>
                                    <div className="form-group">
                                        <input className="form-control" name="txtCelular" id='txtCelular' type="text" placeholder="Celular"
                                            value={datos.txtCelular ? datos.txtCelular : ''}
                                            onChange={(v) => {
                                                v.persist();
                                                setDatos((s) => ({ ...s, txtCelular: v.target.value }))
                                            }} />
                                    </div>
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="form-label">Correo</label>
                                    <div className="form-group">
                                        <input className="form-control" name="txtCorreo" id='txtFolio' type="text" placeholder="Correo"
                                            value={datos.txtCorreo ? datos.txtCorreo : ''}
                                            onChange={(v) => {
                                                v.persist();
                                                setDatos((s) => ({ ...s, txtCorreo: v.target.value }))
                                            }} />
                                    </div>
                                </div>
                                <div className={`col-12 mb-2`}>
                                    <label htmlFor="form-label">Comentarios</label>
                                    <textarea style={{ width: '100%' }} rows={5}
                                        value={datos.txtComentarios ? datos.txtComentarios : ''}
                                        onChange={(v) => {
                                            v.persist();
                                            setDatos((s) => ({ ...s, txtComentarios: v.target.value }))
                                        }}
                                    ></textarea>
                                    {/* <input className="form-control" name="txtComentarios" id='txtComentarios' type="" placeholder="Comentarios"
                            value={datos.txtNombre? datos.txtNombre: ''}
                            onChange={(v) => {
                                v.persist();
                                setDatos((s) => ({...s, txtNombre: v.target.value }))
                            }} /> */}

                                </div>
                                {/* <div className={`col-12 mb-2`}>
                            <Button color="secundary" 
                            onClick={() => onClickAgregar()}
                            >Agregar</Button>
                        </div> */}
                            </div>
                        </div>
                        {/* {lodadingDes ? <div className='col-12 mt-2 d-flex justify-content-center'>
                            <div className="loader-box" style={{ height: 30 }}><span className="rotate dotted"></span></div>
                        </div> : ''} */}
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary"
                        onClick={() => onClickAgregar()}
                    >Guardar</Button>
                    <Button color="danger"
                        onClick={() => toggle()}
                    >Cerrar</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default Default