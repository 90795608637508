import React from 'react';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './common/loader';

import sesionTimer from '../auth/sesion';

const AppLayout = ({children}) => {

    const activechk = () =>{
        console.log('page-wrapper click');
        sesionTimer.validaSesion();
    }

    activechk();

    return (
        <div>
            <Loader />
            <div className="page-wrapper" onClick={activechk}>
                <div className="page-body-wrapper">
                    <Header />
                    <Sidebar />
                    <div className="page-body">
                        {children}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default AppLayout;