import React, { Fragment, useState, useEffect, Component } from 'react';
import Loader from '../common/loaderapp';
import { Link } from 'react-router-dom';
import ProgramContent from "./program_content";

const ProgramDays = ({data,index}) => {

    const [programDays, setProgramDays] = useState({})
    const [posicion, setPosicion] = useState('')
    const [programDetails, setProgramDetails] = useState([])
    const [load, setLoad] = useState(true)
    const [actualizar, setActualizar] = useState(false)

    useEffect(() => {
        setProgramDays(data)
        switch (index) {
            case 0:
                setPosicion('Uno');
                break;
            case 1:
                setPosicion('Dos');
                break;
            case 2:
                setPosicion('Tres');
                break;
            case 3:
                setPosicion('Cuatro');
                break;
            case 4:
                setPosicion('Cinco');
                break;
        }

        fetch(`/p_program?id=${data.ID_PD}`, {headers: {'Content-Type': 'application/json'}})
        .then(res => res.json())
        .then(res => {
            console.log(res);
            setLoad(false)
            setActualizar(false)
            setProgramDetails(res)
        })
        .catch(err => console.log('Error:',err))
    }, [data,index,actualizar]);

    const {ID_PD, ID_EVE_PD, TITLE_PD, SUBTITLE_PD, DATE_PD, DATE_TEXT_PD, TEXT_PD, IS_VISIBLE_PD} = programDays;

    return ( 
        <Fragment>
            <div className="accordion-item">
                <div className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${posicion}`} aria-expanded="false" aria-controls={`flush-collapse${posicion}`}>
                        <h6 className="m-0">{TITLE_PD}</h6>
                    </button>
                </div>
                <div id={`flush-collapse${posicion}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body row">
                        <div className="col-12 mb-4">
                            <Link to={`/program-edit/${ID_PD}/0`}>
                                <button className="btnOrange">Add new event</button>
                            </Link>
                        </div>
                        {
                            load ?
                            <Loader/>
                            :
                            programDetails.map(x=><ProgramContent data={x} setActualizar={setActualizar} />)
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
 
export default ProgramDays;