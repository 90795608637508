import React, { Fragment, useState, useEffect, Component } from 'react';
import Loader from '../common/loaderapp';
import ProgramDays from "./program_days";

const Program = () => {
    const [programDays, setProgramDays] = useState([])
    const [load, setLoad] = useState(true)

    useEffect(() => {
        fetch('/p_program_days', {headers: {'Content-Type': 'application/json'}})
        .then(res => res.json())
        .then(res => {
            console.log(res);
            setProgramDays(res)
            setLoad(false)
        })
        .catch(err => console.log('Error:',err))
    }, []);

    return (
        <Fragment>
            {
                load ?
                <Loader/>
                :
                <Fragment>
                    <div className="col-sm-12 mt-5">
                        <div className="row">
                            <div className="col-sm-12 mb-2 mt-3"></div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <h5>Program</h5>
                                <p>You can edit the hour, title, room name, speaker name and add extra information.</p>
                                <p>Only the filled fields will appear online</p>
                            </div>
                        </div>
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            {programDays.map((x,index)=><ProgramDays key={x.ID_PD} data={x} index={index}/>)}     
                        </div>
                    </div>
                </Fragment >
            }
        </Fragment>
    );
}

export default Program;