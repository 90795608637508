import React, { Fragment, useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../common/loaderapp';
import AvisoCard from './aviso_card';

const Avisos = () => {
    const [avisosI, setavisosI] = useState([])
    const [actualizar, setActualizar] = useState(false)
    const [load, setLoad] = useState(true)

    useEffect(() => {
        fetch('/p_avisos', {headers: {'Content-Type': 'application/json'}})
        .then(res => res.json())
        .then(res => {
            console.log(res);
            setavisosI(res)
            setActualizar(false)
            setLoad(false)
        })
        .catch(err => console.log('Error:',err))
    }, [actualizar]);

    return (
        <Fragment>
            {
                load ? 
                <Loader/>
                :
                <div className="col-sm-12 mt-5 mb-5">
                    <div className="row">
                        <div className="col-sm-12 mb-2 mt-3">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h5>Avisos</h5>
                            {/* <p>You can edit the title and content, also you cand add a link or a file to download.</p> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <Link to={`/aviso-edit/0`}><a className="btnOrange" href='#'>Add new aviso</a></Link>
                        </div>
                    </div>
                    <div className="row">
                        {
                            avisosI.map(x=><AvisoCard data={x} setActualizar={setActualizar}/>)
                        }
                    </div>
                </div>
            }
        </Fragment >
    );
}

export default Avisos;