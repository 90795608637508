import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';

const CardGroup = ({data,setActualizar}) => {
    const [group, setGroup] = useState({})
    useEffect(() => {
      setGroup(data)
    }, [data]);

    function eliminarGroup(){
        fetch('/p_group', {
            method: 'DELETE',
            body: JSON.stringify({id: data.ID_GMD}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            setActualizar(true);
        })
        .catch(err => console.log('Error: '+err))
    }

    return (
    <Fragment>
        <div className="col-md-6 mb-4">
            <div className="cardSpeaker">
                <div className="cardBody">
                    <p><strong>Group name: </strong>{group.NOMBRE_GRUPO_GMD}</p>
                </div>
                {group.ID_GMD !=1 &&
                <div className="cardFooter buttonsTicket">
                    <Link to={`/group-edit/`+group.ID_GMD}><button className="btnTicket edit"><i className="fa-regular fa-pen-to-square"></i> Edit</button></Link>
                    <button className="btnTicket delete" onClick={eliminarGroup}><i className="fa-regular fa-trash-can"></i> Delete</button>
                </div>}
            </div>
        </div>
    </Fragment>
    );
}
 
export default CardGroup;