import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';

const CardSpeaker = ({data,setActualizar}) => {
    const [speaker, setSpeaker] = useState({})
    useEffect(() => {
      setSpeaker(data)
    }, [data]);

    function eliminarSpeaker(){
        fetch('/p_speaker', {
            method: 'DELETE',
            body: JSON.stringify({id: data.ID_SPK}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            setActualizar(true);
        })
        .catch(err => console.log('Error: '+err))
    }

    function ocultarMostrarSpeaker(){
        fetch('/p_speaker', {
            method: 'SUBSCRIBE',
            body: JSON.stringify({id: data.ID_SPK}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            console.log(res);
            setActualizar(true);
        })
        .catch(err => console.log('Error: '+err))
    }

    return (
    <Fragment>
        <div className="col-md-6 mb-4">
            <div className="cardSpeaker">
                <div className="cardBody">
                    <p><strong>Speaker name: </strong>{speaker.SPEAKER_NAME_SPK}</p>
                    <p><strong>Company/Job: </strong>{speaker.COMPANY_JOB_SPK}</p>
                    <p><strong>Bio: </strong>{speaker.BIO_SPK}</p>
                    <p><strong>Picture: </strong>{speaker.PICTURE_SPK}</p>
                    {/* <p><strong>Order: </strong> 1</p> */}
                </div>
                <div className="cardFooter buttonsTicket">
                    <Link to={`/speakers-edit/`+speaker.ID_SPK}><button className="btnTicket edit"><i className="fa-regular fa-pen-to-square"></i> Edit</button></Link>
                    <button className="btnTicket delete" onClick={eliminarSpeaker}><i className="fa-regular fa-trash-can"></i> Delete</button>
                    {
                        !speaker.IS_VISIBLE_SPK ?
                        <button className="btnTicket unhide" onClick={ocultarMostrarSpeaker}><i className="fa-regular fa-eye"></i> Unhide</button>
                        :<button className="btnTicket hide" onClick={ocultarMostrarSpeaker}><i className="fa-regular fa-eye-slash"></i> Hide</button>
                    }
                </div>
            </div>
        </div>
    </Fragment>
    );
}
 
export default CardSpeaker;