import React, { Fragment, useState, useEffect, Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../common/loaderapp';
import axios from 'axios';

const InfoEdit = () => {
    let navigate = useNavigate();
    const { id } = useParams();

    const [load, setLoad] = useState(true)
    const [info, setInfo] = useState({title:'',content:'',link:'',link_button_name:'',is_visible_link:true, file:'',file_name:'',is_visible_file:true})
    const [key, setKey] = useState(0); 
    const [files, setFiles] = useState(null);

    const {title,content,link,link_button_name,is_visible_link,file,file_name,is_visible_file} = info

    useEffect(() => {
        console.log('el id', id);
        if(id != 0){
            fetch('/p_info', {
                method: 'PATCH',
                body: JSON.stringify({id}),
                headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then(res => {
				console.log('El INFO',res);
                if(res.length != 0){
                    const { ID_PI,TITLE_PI,CONTENT_PI,LINK_PI,LINK_BUTTON_NAME_PI,IS_VISIBLE_LINK_PI,FILE_PI,FILE_NAME_PI,IS_VISIBLE_FILE_PI,ORDER_PI,IS_VISIBLE_PI } = res[0];
                    setInfo({title:TITLE_PI,content:CONTENT_PI,link:LINK_PI,link_button_name:LINK_BUTTON_NAME_PI,is_visible_link:IS_VISIBLE_LINK_PI, file:FILE_PI,file_name:FILE_NAME_PI,is_visible_file:IS_VISIBLE_FILE_PI})
                }
            })
            .catch(err => console.log('Error: '+err))
        }
        setLoad(false);
    }, [])

    function setInfoPractical() {
        fetch('/p_info', {
            method: id != 0 ? 'PUT' : 'POST',
            body: JSON.stringify({...info,id}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            console.log('El info',res);
            navigate('/info')
        })
        .catch(err => console.log('Error: '+err))
    }

    const handleFileChange = (event) => {
        console.log('FILE',event.target.files[0]);
        const selectedFile = event.target.files[0];
        setInfo({ ...info, 'file':selectedFile.name})
        setFiles(selectedFile);
    };

    const handleUpload = async () => {
        if (files) {
            const formData = new FormData();
            formData.append('file', files);
            try {
                const response = await axios.post('/uploadInfo', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log('File uploaded successfully:', response.data);
                setInfoPractical();
            } catch (error) {
            console.error('Error uploading file:', error);
            }
        } else {
            console.error('No file selected');
            setInfoPractical();
        }
    };

    const actInfo=(e)=>setInfo({ ...info, [e.target.name]:(e.target.value)})
    const actCheck=(e)=>setInfo({ ...info, [e.target.name]:(e.target.checked)})

    return (
        <Fragment>
        {
            load ? 
            <Loader/> :
            <Fragment>
                <div className="col-sm-12 mt-5 mb-5">
                    <div className="row">
                        <div className="col-sm-12 mb-2 mt-3">
                            {/* <Breadcrumb title="President" parent="Inicio" /> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h5>Edit information or Add new information</h5>
                            <p>You can edit the title and content, also you cand add a link or a file to download.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-4">
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6 mb-3">
                            <label>Title:</label>
                            <input className="form-control" name='title' value={title} onChange={actInfo}></input>
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <label>Content:</label>
                            <textarea className="form-control" value={content} onChange={actInfo} name='content'></textarea>
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <label>Link:</label>
                            <input className="form-control mb-3" placeholder="link" value={link} onChange={actInfo} name='link'></input>
                            <input className="form-control mb-3" placeholder="Button name" value={link_button_name} onChange={actInfo} name='link_button_name'></input>
                            <div class="form-check">
                                <input class="form-check-input inputAuto" type="checkbox" value="" id="flexCheckDefault" name='is_visible_link' checked={is_visible_link} onChange={actCheck}></input>
                                <label class="form-check-label" for="flexCheckDefault">
                                    Show on Website
                                </label>
                            </div>
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <label className="form-label">File to dowloand:</label>
                            <input className="form-control mb-3" placeholder="Button name" name='file_name' value={file_name} onChange={actInfo}></input>
                            <input key={key} className="form-control mb-3" type="file" id="formFile" onChange={handleFileChange}></input>
                            <div class="form-check">
                                <input class="form-check-input inputAuto" type="checkbox" value="" id="flexCheckDefault" name='is_visible_file' checked={is_visible_file} onChange={actCheck}></input>
                                <label class="form-check-label" for="flexCheckDefault">
                                    Show on Website
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <button type="button" class="btn btn-primary" onClick={handleUpload}>Save changes</button>
                        </div>
                    </div>
                </div>
            </Fragment >
        }
        </Fragment>
    );
}

export default InfoEdit;