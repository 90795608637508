
import React from 'react'

const Default = (props) => {
    const onChange = (e) => {
        let value = e.target.value
        let item = props.datos.find((s) => s.clave == value)
        props.onChange({item, e})
    }

    return (
    <select
        {...props}
        datos={null}
        onChange={props.onChange?onChange:null}>
        {props.placeholder?<option value="" hidden>{props.placeholder}</option>: 'Seleccionar opción'}
        {props.datos?
            props.datos.map((v, i) => (
            <option key={v.clave+'-'+i} value={v.clave}>{v.label}</option>
            ))
        : ''}
    </select>)
}

export default Default