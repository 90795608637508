import React, { Fragment, useState, useEffect } from 'react';
// import Tablereact from '../common/tabulator/tablereact'
// import Breadcrumb from '../common/breadcrumb';
import TablaFetch from '../common/tabla_fetch'
import ModalNuevoRegistro from './modal_nuevo_registro'
import ModalEditarRegistro from './modal_editar_registro'
import DropDown from '../common/dropdown'
import notie from 'notie';
import tools from '../../js/tools'

import {
    CheckCircle, XSquare
} from 'react-feather';

const Default = () => {
    const [refreshTabla, setRefreshTabla] = useState(false)
    const [modalNuevo, setModalNuevo] = useState({ estado: false })
    const [modalEditar, setModalEditar] = useState({ estado: false, datos: {} })
    const [qrSelected, setQrSelected] = useState({ estado: false, escaneo: false, datos: {} })
    const [idErc, setIdErc] = useState(0)

    let barcode = "";
    let interval;

    const optionsDrop = [
        { label: <div><i className="fa fa-print"></i> Imprimir</div>, value: 1 },
        { label: <div><i className="fa fa-pencil-square-o"></i> Editar</div>, value: 2 },
        { label: <div><i className="fa fa-trash-o"></i> Eliminar</div>, value: 3 },
        { label: <div><i className="fa fa-thumbs-o-up" aria-hidden="true"></i> Entregar pulsera</div>, value: 4 },
        // { label: <div><i className="fa fa-check-square-o"></i> Eliminar Seleccionado</div>, value: 3},
    ]

    const styleColumn = {
        fontSize: 11,
    }

    const copyPage = async (dato) => {
        try {
            await window.navigator.clipboard.writeText(dato.text);
            //   console.log('Page URL copied to clipboard');
        } catch (err) {
            //   console.error('Failed to copy: ', err);
        }
    }

    const headerFac = [
        // { name: '-', clave: 'FOLIO_USUARIO', width: '5%', visible: true, type: 'check', styleHeader: { textAlign: 'center' }, styleColumn: { textAlign: 'center' } },
        {
            name: 'REGISTRO', clave: 'FOLIO_ERC', width: 0, visible: true, styleColumn,
            render: (v) => {
                return <div>
                    <div style={{ fontSize: 14 }}>{v.FECHA_REGISTRO}</div>
                    <div className="alert alert-light text-center p-0 rounded m-0" role="alert" style={{ cursor: 'pointer', backgroundColor: '#F7F777', color: 'black' }}
                        onClick={() => {
                            copyPage({ text: v.FOLIO_USUARIO })
                        }}
                    >
                        <div style={{ fontSize: 12 }}><strong>F{v.FOLIO_USUARIO}</strong></div>
                    </div>
                    <div style={{ fontSize: 16 }}>R: <strong>{v.ID_ECU}</strong></div>
                    {/* <div style={{ fontSize: 16 }}>C: <strong>{v.ID_ECU}</strong></div> */}
                </div>
            }
        },
        {
            name: 'CONTACTO', clave: 'TIPO_PAGO_DESC', width: 0, visible: true, styleColumn,
            render: (v) => {
                return <div>
                    <span style={{ fontSize: 16 }}><strong>{v.NOMBRES_ECU} {v.APELLIDOS_ECU}</strong></span><br />
                    {v.CORREO_ECU}<br />
                    {/* Tel: {v.CELULAR_ECU} */}
                </div>
            }
        },
        {
            name: 'EMPRESA', clave: 'EMPRESA_ERC', width: 0, visible: true, styleColumn,
            render: (v) => {
                return <div>
                    {v.ID_EC > 3? <div style={{ fontSize: 14 }}><strong>N/A</strong></div>: <Fragment>
                        <div style={{ fontSize: 13 }}><strong>{v.EMPRESA_ERC}</strong></div>
                        <div><strong>RS:</strong> {v.RAZON_SOCIAL_DF}</div>
                        <div><strong>RFC:</strong> {v.RFC_DF}</div>
                    </Fragment>}
                </div>
            }
        },
        {
            name: 'TIPO CARNET', clave: 'TIPO_CARNET', width: 0, visible: true, styleColumn,
            render: (v) => {
                return <div style={{fontSize: 16}}>
                   <strong>{v.TIPO_CARNET}</strong>
                </div>
            }
        },
        {
            name: 'RECIBIO PULSERA', clave: 'RECIBIO_PULSERA', width: 0, visible: true, styleColumn,
            render: (v) => {
                return <div style={{fontSize: 16}}>
                   <strong>{v.RECIBIO_PULSERA? 'Recibió pulsera': 'Pendiente pulsera'}</strong>
                </div>
            }
        },
        {
            name: 'IMPRIMIO', clave: 'IMPRIMIO_CARNET', width: 0, visible: true, styleColumn,
            render: (v) => {
                return <div className='d-flex justify-content-start'>
                    <div className='text-center'>{v.IMPRIMIO_CARNET ? <CheckCircle style={{ color: 'green' }} /> : <XSquare style={{ color: 'red' }} />}</div>
                </div>
                
            }
        },
        // {
        //     name: 'TIPO CARNET', clave: 'ESTATUS_PAGO', width: 0, visible: true, styleColumn,
        //     render: (v) => {
        //         return <div className="alert alert-light text-center p-2 rounded" role="alert" style={{ backgroundColor: v.ESTATUS_PAGO_COLOR_FONDO, color: v.ESTATUS_PAGO_COLOR_TEXTO }}>
        //             <div><strong>{v.ESTATUS_PAGO_DESC}</strong></div>
        //         </div>
        //     }
        // },
        {
            name: 'PAGO ESTATUS', clave: 'ESTATUS_PAGO', width: 0, visible: true, styleColumn,
            render: (v) => {

                return  <div>
                {v.ID_EC > 3? <div style={{ fontSize: 14 }}><strong>N/A</strong></div>: <Fragment>
                    <div className="alert alert-light text-center p-2 rounded" role="alert" style={{ backgroundColor: v.ESTATUS_PAGO_COLOR_FONDO, color: v.ESTATUS_PAGO_COLOR_TEXTO }}>
                        <div><strong>{v.ESTATUS_PAGO_DESC}</strong></div>
                    </div>
                </Fragment>}
            </div>
            }
        },
        // {
        //     name: 'Total', clave: 'COMPROBANTE_TOTAL', width: '20%', visible: true, styleHeader: { textAlign: 'center' }, styleColumn: { ...styleColumn, textAlign: 'center' },
        //     // render: (v) => {
        //     //     return <strong><NumberFormat thousandSeparator={true}
        //     //         displayType='text'
        //     //         prefix={`$ `}
        //     //         onValueChange={(values) => null}
        //     //         value={(tools.toFixed2(v.COMPROBANTE_TOTAL))} /></strong>
        //     // }
        // },
        {
            name: 'Opciones', clave: 'COMPROBANTE_SERIE', width: 0, visible: true,
            styleHeader: { textAlign: 'center' }, styleColumn: { ...styleColumn,},
            render: (v) => {
                return <div className='d-flex justify-content-center'>
                        <DropDown
                            title={<div><i className="fa fa-align-justify"></i></div>}
                            onClick={(e) => {
                                if (e.value === 1) {
                                    setTimeout(() => setRefreshTabla((s) => !s), 2000)
                                    const ipcRenderer = window.ipcRenderer;
                                    ipcRenderer.send('print', {
                                        estado: 1,
                                        TIPO_ASISTENTE: 1,
                                        ID_ASISTENTE: v.ID_ERC,
                                        NOMBRE: v.NOMBRES_ECU ? v.NOMBRES_ECU.toUpperCase() : '',
                                        APELLIDO: v.APELLIDOS_ECU ? v.APELLIDOS_ECU.toUpperCase() : '',
                                        FOLIO_USUARIO: v.FOLIO_USUARIO
                                    })
                                }
        
                                if (e.value === 2) {
                                    setModalEditar((s) => ({...s, estado: !s.estado, datos: v}))
                                }
        
                                if (e.value === 3) {
                                    notie.confirm({
                                        text: `Se eliminara ${v.NOMBRES_ECU} ${v.APELLIDOS_ECU} ¿Esta de acuerdo?`,
                                        submitText: 'Si, eliminar', // optional, default = 'Yes'
                                        cancelText: 'No, cancelar', // optional, default = 'Cancel'
                                        position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
                                        submitCallback: () => {
                                            tools.useFetch({
                                                ruta: 'rt_asistentes_eliminar',
                                                method: 'POST',
                                                body: v
                                            }, (response) => {
                                                setRefreshTabla((s) => !s)
                                                return notie.alert({ type: 'success', text: 'Carnet eliminado correctamente' })
                                            })
                                        }, // optional
                                        cancelCallback: () => {
                                          
                                        }// optional
                                      })
                                }

                                if (e.value === 4) {
                                    tools.useFetch({
                                        ruta: 'rt_asistentes_entrega_pulsera',
                                        method: 'POST',
                                        body: v
                                    }, (response) => {
                                        setRefreshTabla((s) => !s)
                                        return notie.alert({ type: 'success', text: 'Pulsera entregada' })
                                    })
                                }
                            }}
                            data={optionsDrop}
                        />
                </div>
                
            }
        },
    ]

    function handleBarcode(scanned_barcode) {
        fetch('https://agm-admin.iisrp.com/asistencia_qr', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                qr: scanned_barcode
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.datos.estado === 1) {
                    let carnets = response.datos

                    if (response.tipo == 1) {
                        setRefreshTabla((s) => !s)
                        setIdErc(carnets.id_erc)
                        copyPage({ text: carnets.FOLIO_USUARIO })
                        setQrSelected((v) => ({ ...v, estado: false, escaneo: true, datos: carnets }))
                        const ipcRenderer = window.ipcRenderer;
                        window.document.querySelector("#last-barcode").innerHTML = (carnets.NOMBRE ? carnets.NOMBRE.toUpperCase() : '') + ' ' + (carnets.APELLIDO ? carnets.APELLIDO.toUpperCase() : '') + ' | ' (carnets.CARNET ? carnets.CARNET.toUpperCase() : '') + ' | ' + carnets.FOLIO_USUARIO
                        carnets.TIPO_ASISTENTE = 1
                        ipcRenderer.send('print', carnets, scanned_barcode)
                    }

                    if (response.tipo == 2) {
                        setRefreshTabla((s) => !s)
                        setIdErc(response.datos.id_erc)
                        // copyPage({text: response.FOLIO_USUARIO})
                        // setQrSelected((v) => ({...v, estado: false, escaneo: true, datos: response }))
                        // const ipcRenderer = window.ipcRenderer;
                        // window.document.querySelector("#last-barcode").innerHTML = response.NOMBRE + ' ' + response.APELLIDO + ' | '+response.FOLIO_USUARIO
                        // ipcRenderer.send('print', response, scanned_barcode)
                    }

                }
                else if (response.datos.estado === 0) {
                    window.document.querySelector("#last-barcode").innerHTML = 'Por favor intentelo de nuevo'
                }
            }).catch(error => console.log(error))
    }

    useEffect(() => {
        window.document.addEventListener("keydown", function (evt) {
            if (interval) clearInterval(interval);
            if (evt.code == "Enter") {
                if (barcode) handleBarcode(barcode);
                barcode = "";
                return;
            }
            if (evt.key != "Shift") barcode += evt.key;
            interval = setInterval(() => (barcode = ""), 20);
        });

        window.document.querySelector('body').addEventListener('mouseleave', (event) => {
            // console.log(qrSelected, event)
            if (!qrSelected.estado) {
                setQrSelected((v) => ({ ...v, estado: false }))
            }
        })
    }, []);

    return (
        <div>
            <ModalNuevoRegistro modal={modalNuevo} setModal={setModalNuevo} setRefreshTabla={setRefreshTabla}/>
            <ModalEditarRegistro modal={modalEditar} setModal={setModalEditar} setRefreshTabla={setRefreshTabla}/>
            <Fragment
                // onClick={() => {
                //     setQrSelected(false)
                // }}GRUPAL123
            >
                <div className="col-sm-12 mt-3"
                    onClick={() => {
                        if(qrSelected.estado){
                            setQrSelected((v) => ({...v, estado: false}))
                        }
                    }}
                >
                    <div className="card">
                        <div className="card-header">
                            <div className='row'>
                                <div className='col-sm-6 mb-2'>
                                    <h5>Carnets Convencionistas</h5>
                                </div>

                                <div className='col-sm-12 mb-2 d-flex justify-content-between' style={{height: 50}}>
                                    <div id='last-barcode' className='mb-2 d-flex justify-content-center align-items-center' 
                                        style={{
                                            width: '100%', 
                                            height: '100%', 
                                            border: '0.1em solid', 
                                            cursor: 'text', 
                                            color: qrSelected.estado? 'black': 'black', 
                                            backgroundColor: qrSelected.estado? '#39F203': 'white'}
                                        }
                                        onClick={() => {
                                            setQrSelected((v) => ({...v, estado: true}))
                                        }}
                                    >
                                        <strong>{qrSelected.escaneo? `${qrSelected.datos.NOMBRE} ${qrSelected.datos.APELLIDO} | ${qrSelected.datos.FOLIO_USUARIO}`: 'Click aquí para "Escanear QR"'}</strong>
                                    </div>
                                    <button type="button" className="btn btn-dark ml-2" 
                                        onClick={() => {
                                            if(qrSelected.escaneo){
                                                copyPage({text: qrSelected.datos.FOLIO_USUARIO})
                                            }
                                        }}
                                    >Copiar Folio</button>
                                </div>
                            </div>
                        </div>
                        <div className=" datatable-react" >
                            <TablaFetch
                                header={headerFac}
                                ruta={'rt_asistentes'}
                                clave='ID_ECU'
                                isPaginador={true}
                                sizePaginas={5}
                                refresh={refreshTabla}
                                html={() => {
                                    return <div className=' d-flex justify-content-end mb-2 mr-4'>
                                    {/* <button type="button" className="btn btn-success mr-3"
                                        onClick={() => {
                                            setRefreshTabla((s) => !s)
                                            setIdErc(0)
                                        }}
                                    ><i className="fa fa-refresh"></i> Limpiar Filtro</button> */}
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => {
                                            setModalNuevo((s) => ({...s, estado: !s.estado}))
                                        }}
                                    >Nuevo Carnet</button>
                                </div>
                                }}
                                params={{
                                    // ID_DE: 9,
                                    // TIPO_CFDI: 4,
                                    ID_ERC: idErc,
                                    size: 30
                                }}
                                onClickCheck={(d) => {
                                    // setSelectFac(d)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        </div>
    );
};

export default Default;