import React from 'react';

const SesionContexto = React.createContext();

export const SesionProvider = ({children}) => {

    let sesionGuardada = localStorage.getItem('auth');
	sesionGuardada = JSON.parse(sesionGuardada || '{}');

	const [sesion, setSesion] = React.useState(sesionGuardada);

    return (
    <SesionContexto.Provider value={{sesion, setSesion}}>
        {children}
    </SesionContexto.Provider>
    )
}

export default SesionContexto;