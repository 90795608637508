import React, { useState, useEffect, Fragment } from "react";
import { animations } from "@formkit/drag-and-drop";
import { useDragAndDrop } from "@formkit/drag-and-drop/react";
import { Link } from 'react-router-dom';
import CardSpeaker from "./card_speaker";
import Loader from '../common/loaderapp';

const Speakers = () => {
    const [speakers, setSpeakers] = useState([])
    const [actualizar, setActualizar] = useState(false)
    const [load, setLoad] = useState(true)

    useEffect(() => {
        fetch('/p_speaker', {headers: {'Content-Type': 'application/json'}})
        .then(res => res.json())
        .then(res => {
            console.log(res);
            setSpeakers(res)
            setActualizar(false)
            setLoad(false)
        })
        .catch(err => console.log('Error:',err))
    }, [actualizar]);

    return (
        <Fragment>
            {load ? 
            <Loader/>: 
            <Fragment>
                <div className="col-sm-12 mt-5 mb-5">
                    <div className="row">
                        <div className="col-sm-12 mb-2 mt-3"></div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h5>Speakers</h5>
                            <p>You can edit the name, photo and title, also you can add new speakers or delete them.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <Link to="/speakers-edit/0">
                                <button className="btnAddSpeaker btnOrange">Add new Speaker</button>
                            </Link>
                        </div>
                    </div>
                    <ItemSpeakers data={speakers} setActualizar={setActualizar}/>
                    {/* <div className="row">
                        { speakers.map(x=><CardSpeaker key={x.ID_SPK} data={x} setActualizar={setActualizar}/>) }
                    </div> */}
                    
                </div>
            </Fragment >
            }
        </Fragment>
    );
}


const ItemSpeakers = ({data,setActualizar}) => {
    const [parent, speakers] = useDragAndDrop(
        data,
        { 
            plugins: [
            animations()] 
        }
    )

    useEffect(() => {
      console.log(speakers.map(x=>x.ID_SPK));
    }, [speakers])
    

    return (
        <div className="row" ref={parent}>
            { speakers.map(x=><CardSpeaker key={x.ID_SPK} data={x} setActualizar={setActualizar}/>) }
        </div>
    )
}


export default Speakers;