import notie from 'notie'

let timeout,
    interval,
    timer_session;

const iniciaTimer = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
        cerrandoSesion()
    }, 1000*60*60*24-15000)
}

const cerrandoSesion = () => {
    let tiempo_restante = 30;
    notie.force({
        type: 3,
        text: 'Su sesión se cerrará en <strong id="timer_session">30</strong> segundos, presione OK para continuar su sesión.',
        buttonText: 'OK',
        callback: () => {
            fetch('/estatus_sesion')
            .then((res) => {
                clearInterval(interval)
                iniciaTimer()
                if(res.status == 200)
                    notie.alert({ type: 4, text: 'Su sesión no se cerrará.', stay: false })
                else{
                    notie.alert({text: 'Su sesión ha terminado, es necesario vuelva a iniciar sesión.', type: 4, stay: true});
                    window.location.href = '/login';
                }
            }).catch((err) => {
                notie.alert({text: 'Su sesión ha terminado, es necesario vuelva a iniciar sesión.', type: 4, stay: true});
                window.location.href = '/login';
            })
        }
    })
    timer_session = document.getElementById('timer_session')
    interval = setInterval(() => {
        if(tiempo_restante > 1)
            tiempo_restante--;
        else{
            timer_session.innerText = tiempo_restante;
            clearInterval(interval)
        }
    }, 1000)
}

const validaSesion = () => {
    fetch('/estatus_sesion')
    .then(function(res) { return res.json(); })
    .then(function(res) {
        if(res.status == 'OK'){
            iniciaTimer();
        }else{
            localStorage.clear('auth');
            window.location.href = '/login';
        }
    }).catch(function(err){
        localStorage.clear('auth');
        window.location.href = '/login';
    })
}

export default {iniciaTimer, validaSesion}