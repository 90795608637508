import React, { Fragment, useState, useEffect, Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../common/loaderapp';

const GroupList = () => {
    const [load, setLoad] = useState(true)
    const [esCheckAll, setEsCheckAll] = useState(true)
    const [checks, setChecks] = useState([]);
    const [group_name, setGroup_name] = useState('')

    let navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        fetch('/p_members_list', {headers: {'Content-Type': 'application/json'}})
        .then(res => res.json())
        .then(res => {
            //console.log('Miembros',res);

            if(id == 0){
                let nuevaLista = res.map((item) => {
                    return { ...item, checked: true };
                })
                setChecks(nuevaLista)
                setLoad(false)
            }
            else{

                fetch('/p_group', {
                    method: 'PATCH',
                    body: JSON.stringify({id}),
                    headers: {'Content-Type': 'application/json'}
                })
                .then(ress => ress.json())
                .then(ress => {
                    const array = ress['LISTA_MIEMBROS_GMD'].split(",").map(num => parseInt(num));
                    //console.log('El group array',array);

                    let nuevaLista = res.map((item) => {
                        return { ...item, checked: array.includes(item.ID_MI) };
                    })
                    setGroup_name(ress['NOMBRE_GRUPO_GMD'])
                    setChecks(nuevaLista)
                    setLoad(false)
                })
                .catch(err => console.log('Error: '+err))

            }


        })
        .catch(err => console.log('Error:',err))
    }, []);

    const [membersSelect, setMembersSelect] = useState([]);

    const membersSeleccionados = () => {
        console.log('entrando');
        let members = [];
        members = checks.filter(check => check.checked === true).map(check => check.ID_MI);
        console.log(members);

        if(id == 0){
            fetch('/p_group', {
                method: 'POST',
                body: JSON.stringify({
                    'name': group_name,
                    'lista': members
                }),
                headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then(res => {
                console.log('Miembros',res);
                navigate('/group')
            })
            .catch(err => console.log('Error:',err))
        }
        else{
            fetch('/p_group', {
                method: 'PUT',
                body: JSON.stringify({
                    id,
                    'name': group_name,
                    'lista': members
                }),
                headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then(res => {
                console.log('Miembros',res);
                navigate('/group')
            })
            .catch(err => console.log('Error:',err))
        }

    }

    const toggleCheck = (id) => {
        setChecks(checks.map(check => 
            check.ID_MI === id ? { ...check, checked: !check.checked } : check
        ));
    };

    const setCheckAll = () => {
        setEsCheckAll(!esCheckAll);
        setChecks(checks.map(check => ({ ...check, checked: !esCheckAll })));
    }

    return (
        <Fragment>
            {
                load ?
                <Loader/>
                :
                <Fragment>
                    <div className="col-sm-12 mt-5">
                        <div className="row">
                            <div className="col-sm-12 mb-2 mt-3"></div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <h5>Group</h5>
                            </div>
                        </div>
                        <div className="accordion accordion-flush row d-flex justify-content-between" id="accordionFlushExample">
                            <div className="form-group col-md-8 mb-3">
                                <label>Group name:</label>
                                <input className="form-control" name='room_venue' value={group_name} onChange={(e) => setGroup_name(e.target.value)}></input>
                            </div>
                            <div className="col-4 mt-4">
                                <button type="button" className="btn btn-primary btn-lg" onClick={membersSeleccionados}>Save changes</button>
                            </div>
                            <div className='row'> 
                                <label className='d-flex'>
                                    <input
                                        style={{ marginRight: '15px', marginTop: '5px' }}
                                        type="checkbox" 
                                        checked={esCheckAll} 
                                        onChange={setCheckAll}
                                    />
                                    <p style={{ marginTop: '0px' }}><strong>Check All</strong></p>
                                </label>
                            </div>
                            {checks.map(check => (
                                <div key={check.ID_MI} className="col-md-6 mb-4">
                                    <div className="cardSpeaker" onClick={() => toggleCheck(check.ID_MI)}>
                                        <div className="cardBody d-flex">
                                            <input
                                                style={{ marginRight: '15px', marginTop: '5px' }}
                                                type="checkbox" 
                                                checked={check.checked} 
                                                onChange={() => toggleCheck(check.ID_MI)}
                                            />
                                            <div className="">
                                                <p>Name:<strong>{check.NOMBRE}</strong></p>
                                                <p>Email:<strong>{check.CORREO_MI}</strong></p>
                                            </div>
                                            {/* <p><strong>Order: </strong> 1</p> */}
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                </Fragment >
            }
        </Fragment>
    );
}

export default GroupList;