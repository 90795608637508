import React, { Fragment , useState , useEffect } from 'react';

const Loader = () => {
    const [show, setShow] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
          }, 60000);
        
    },[show]);
    return (
        <Fragment>
            <div className="col-sm-12">
                <div className="loader-box d-flex justify-content-center">
                    <span className="rotate dashed colored">
                    </span>
                </div>  
            </div>
        </Fragment>
    );
};

export default Loader;