import React, { Fragment, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import tools from "../../js/tools";
import TablaPro from "../../componentes/tabla";
import ModalEditarCarnet from "./modal_editar";
import ModalImpresion from "./modal_impresion";

const Default = () => {
    // const history = useNavigate();
    const [modalEditar, setModalEditar] = useState({ estado: false })
    const [modalImpresion, setModalImpresion] = useState({ estado: false, datos: [] })
    const [esRefresh, setEsRefresh] = useState(true)
    const [qrSelected, setQrSelected] = useState({ estado: false, escaneo: false, datos: {} })
    const [filtros, setFiltros] = useState({
        BUSQUEDA: ''
    })
    let timeout, busqueda_pendiente = '';
    let textQr = '', idTimemOut = null

    const evaluarEscribiendo = (e) => {
        if (timeout) {
            clearTimeout(timeout)
        }

        busqueda_pendiente = e.target.value;

        timeout = setTimeout(() => {
            setFiltros((s) => ({ ...s, BUSQUEDA: busqueda_pendiente, ID_RC: '', ID_RAC: '' }))
        }, 500)
    }

    const onDecencriptar = async (e) => {
        let dec = await window.ipcRenderer.invoke('decencriptar', e)
        let estatus = false

        // Individual
        if(dec.TIPO_QR === 1){
            estatus = true
            setFiltros((s) => ({ ...s, ID_RC: '', ID_RAC: dec.ID_RAC }))
        }
        
        // Grupal
        if(dec.TIPO_QR === 2){
            estatus = true
            setFiltros((s) => ({ ...s, ID_RC: dec.ID_RC, ID_RAC: '' }))
        }

        if(estatus == false){
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `QR no valido, intente buscar por su nombre`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if(dec.ID_RC){
            fetch('/rt_mesa_registro', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({...dec})
            })
            .then((response) => response.json())
            .then((response) => {
                // if(dec.TIPO_QR === 1){
                //     onImprimir({TIPO_QR: dec.TIPO_QR, ...response.result[0]})
                // }else{
                //     if(response.result.length === 1){
                //         onImprimir({TIPO_QR: dec.TIPO_QR, ...response.result[0]})
                //     }
                // }

                setModalImpresion((s) => ({
                    ...s,
                    estado: !s.estado,
                    datos: response.result.map((v) => ({...v, TIPO_QR: dec.TIPO_QR}))
                }))
            })
        }
    }

    const onImprimir = async (datos) => {
        console.log('QR',datos)
        let response = await window.ipcRenderer.invoke('imprimir', {datos})
        
        if(response.estado === 1){
            setModalImpresion((s) => ({
                ...s,
                datos: s.datos.map((v) => v.ID_RAC === response.datos.ID_RAC? {...v, IMPRIMIO_CARNET_RAC: 1}: v)
            }))
            
            fetch('/rt_mesaregistro_imprimir_carnet', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(response.datos)
            })
            .then((response) => response.json())
            .then((response) => {
                if(response.estado === 0){
                    return tools.notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Hubo un problema al momento de guardar la impresión, favor de volver a intentar, de lo contrario llamar a soporte técnico`,
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                setFiltros((s) => ({...s, estado: !s.estado}))

                return tools.notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Imprimiendo ${datos.NOMBRES_DP} ${datos.APELLIDOS_DP}`,
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            })
        }

        if(response.estado === 'err_etiqueta'){
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Hubo un detalle en la etiqueta, favor de volver a intentar, de lo contrario llamar a soporte técnico`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        return tools.notie.alert({
            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: `Hubo un problema al momento de imprimir, favor de volver a intentar, de lo contrario llamar a soporte técnico`,
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        })
    }

    useEffect(() => {
        let isS = true;
        // window.api.recieve("tipoQr", (args) => {
        //     //handle on download-progress event
        //     console.log(args)
        // });
        return () => (isS = false);
    }, []);

    return (
        <div>
            <Fragment>
                <div className="col-sm-12 mt-5"
                    onClick={() => {
                        if (qrSelected.estado) {
                            setQrSelected((v) => ({ ...v, estado: false }))
                        }
                    }}
                >
                    <div className="card mt-5">
                        <div className="card-header">
                            <div className="row" style={{position: 'absolute', right: '0%', top: '0%'}}>
                                <div className="col-md-12 d-flex justify-content-end align-items-center">
                                    <a href="#"
                                        style={{
                                            backgroundColor: qrSelected.estado ? '#39F203' : 'white'
                                        }
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key !== 'Enter') {
                                                textQr += e.key
                                            }
                                        }}
                                        onKeyUp={() => {
                                            window.clearTimeout(idTimemOut)
                                            idTimemOut = setTimeout(async () => {
                                                if (textQr.length) {
                                                    console.log('Text', textQr, idTimemOut)
                                                    // window.api.send("imprimir", {textQr, datos: { nombre: 'Daniel' } });
                                                    await onDecencriptar({ textQr })
                                                    textQr = ''
                                                }
                                            }, 1000)
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setQrSelected((v) => ({ ...v, estado: true }))
                                        }}>
                                        <img src="/scan.png" width={60} />
                                    </a>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12 mb-2'>
                                    <h5>Mesa de Registro</h5>
                                </div>
                                <div className="col-xl-8 mb-2 mt-3">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <label style={{ fontWeight: 600, fontSize: 16 }} htmlFor="buscar">
                                                BUSCAR:
                                            </label>
                                            <input
                                                id="input_buscador"
                                                type="text"
                                                className="form-control"
                                                name="buscar"
                                                aria-label=""
                                                aria-describedby="basic-addon1"
                                                onKeyUp={evaluarEscribiendo}
                                            />
                                        </div>
                                        <div class=" col-md-2">
                                            <label style={{ fontWeight: 600, fontSize: 16 }} htmlFor="buscar">
                                                Registro
                                            </label>
                                            <input
                                                id="txtRegistro"
                                                type="text"
                                                className="form-control"
                                                name="buscar"
                                                aria-label=""
                                                aria-describedby="basic-addon1"
                                                value={filtros.ID_RC? filtros.ID_RC: ''}
                                                onChange={(e) => {
                                                    e.persist()
                                                    setFiltros((s) => ({ ...s, ID_RC: e.target.value }))
                                                }}
                                            />
                                        </div>
                                        <div class=" col-md-2">
                                            <label style={{ fontWeight: 600, fontSize: 16 }} htmlFor="buscar">
                                                Asistente
                                            </label>
                                            <input
                                                id="txtAsistente"
                                                type="text"
                                                className="form-control"
                                                name="buscar"
                                                aria-label=""
                                                aria-describedby="basic-addon1"
                                                value={filtros.ID_RAC? filtros.ID_RAC: ''}
                                                onChange={(e) => {
                                                    e.persist()
                                                    setFiltros((s) => ({ ...s, ID_RAC: e.target.value }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 d-flex mb-sm-2 mb-3 justify-content-start align-items-end">
                                    <button type="button" className="btn btn-success" onClick={() => {
                                        window.document.getElementById('input_buscador').value = ''
                                        evaluarEscribiendo({ target: { value: '' } })
                                    }}>
                                        <i className="fa fa-refresh"></i> Limpiar Filtro
                                    </button>
                                    <button type="button" className="btn btn-primary ml-2" onClick={() => {
                                        window.location.href = '/mesaregistro/nuevo'
                                    }}>
                                        <i class="fa fa-address-card" aria-hidden="true"></i> Nuevo Registro
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className=" datatable-react mt-4" >
                            <TablaPro
                                cargando={false}
                                headers={[
                                    {
                                        nombre: 'Id', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                                            return <div>
                                                <strong>{d.FECHA_ALTA_RAC_TEXT}</strong><br />
                                                <span className="text-info"><strong>{d.ID_RC}</strong>-<strong>{d.ID_RAC}</strong></span>
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'Nombre', tipo: 'html', texto: 'NOMBRES_DP', html: (d) => {
                                            return <div>
                                                <strong>{d.NOMBRES_DP} {d.APELLIDOS_DP}</strong><br />
                                                <span className="text-info"><strong>{d.EMPRESA}</strong></span>
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'Contacto', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                                            return d.CORREO_DP ? <div>
                                                <strong>{d.CORREO_DP}</strong><br />
                                                {d.WHATSAPP_DP}
                                            </div> : '-Sin datos-'
                                        }
                                    },
                                    {
                                        nombre: 'Direccion', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                                            return d.ESTADO ? <div>
                                                {d.ESTADO}, Cd. {d.CIUDAD}
                                            </div> : '-Sin datos-'
                                        }
                                    },
                                    {
                                        nombre: 'Tipo Carnet', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                                            return <div>
                                                {d.TIPO_CARNET === 1? 
                                                    <img src="/fullaccess.png" width={100} />
                                                :d.TIPO_CARNET === 2?
                                                    <img src="/taller.png" width={100} />
                                                :d.TIPO_CARNET === 3? 
                                                    <img src="/expositor.png" width={100} />
                                                :d.TIPO_CARNET === 4? 
                                                    <img src="/invitado.png" width={100} />
                                                :d.TIPO_CARNET === 5? 
                                                    <img src="/prensa.png" width={100} />
                                                :d.TIPO_CARNET === 6? 
                                                    <img src="/staff.png" width={100} />
                                                :d.TIPO_CARNET === 7 || d.TIPO_CARNET === 8? 
                                                    <img src="/solocena.png" width={100} />
                                                : <h5><span class="badge badge-primary">Carnet {d.TIPO_CARNET_TEXT}</span></h5>}
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'Estatus', tipo: 'html', texto: 'ID_RAC', html: (d) => {
                                            return <div>
                                                <Fragment>
                                                    {d.IMPRIMIO_CARNET_RAC ?
                                                        <h5><span class="badge badge-success">{d.IMPRIMIO_CARNET_TEXT}</span></h5>
                                                        :
                                                        <h5><span class="badge badge-warning">{d.IMPRIMIO_CARNET_TEXT}</span></h5>
                                                    }
                                                </Fragment>
                                                <Fragment>
                                                    {d.ESTATUS_DPG === -1 ?
                                                        <h5><span class="badge badge-dark">Pago {d.ESTATUS_DPG_TEXT}</span></h5>
                                                        : d.ESTATUS_DPG === 0 ?
                                                            <h5><span class="badge badge-danger">Pago {d.ESTATUS_DPG_TEXT}</span></h5>
                                                            : d.ESTATUS_DPG === 1 ?
                                                                <h5><span class="badge badge-light">Pago {d.ESTATUS_DPG_TEXT}</span></h5>
                                                                : d.ESTATUS_DPG === 2 ?
                                                                    <h5><span class="badge badge-success">Pago {d.ESTATUS_DPG_TEXT}</span></h5>
                                                                    : d.ESTATUS_DPG === 3 ?
                                                                        <h5><span class="badge badge-warning">Pago {d.ESTATUS_DPG_TEXT}</span></h5>
                                                                        : d.ESTATUS_DPG === 4 ?
                                                                            <h5><span class="badge badge-dark">Pago {d.ESTATUS_DPG_TEXT}</span></h5>
                                                                            : d.ESTATUS_DPG === 5 ?
                                                                                <h5><span class="badge badge-info">Pago {d.ESTATUS_DPG_TEXT}</span></h5>
                                                                                : ''}
                                                </Fragment>
                                            </div>
                                        }
                                    },
                                    // { nombre: 'Fecha', tipo: 'individual', texto: 'FECHA_ALTA_RAC_TEXT' },
                                    {
                                        nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                        html: (datos) => {
                                            return <div>
                                                <div class="btn-group dropstart dropleft">
                                                    <button
                                                        class="btn btn-secondary dropdown-toggle"
                                                        type="button"
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        Opciones
                                                    </button>
                                                    <ul
                                                        class="dropdown-menu"
                                                        aria-labelledby="dropdownMenuButton1"
                                                    >
                                                        <li><a className="dropdown-item"
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                window.location.href = '/mesaregistro/editar/'+datos.ID_RC
                                                                // window.open('/mesaregistro/editar/'+datos.ID_RC);
                                                                // setModalEditar((s) => ({
                                                                //     ...s,
                                                                //     estado: !s.estado,
                                                                //     datos
                                                                // }))                                                                
                                                            }}
                                                        >Detalle carnet</a></li>
                                                        <li><a class="dropdown-item" href="#"
                                                            onClick={(e) => {
                                                                // window.api.send("imprimir", { datos: { nombre: 'Daniel' } });
                                                                setModalImpresion((s) => ({
                                                                    ...s,
                                                                    estado: !s.estado,
                                                                    datos: [{...datos, TIPO_QR: 1}]
                                                                }))
                                                            }}
                                                        >Imprimir carnet</a></li>
                                                        {/* <li><a class="dropdown-item" href="#">Entrega de kit</a></li> */}
                                                        {/* <li><a class="dropdown-item" href="#">Cambiar tipo carnet</a></li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    }
                                ]}
                                row={{
                                    ignoreColumns: ['Detalle'],
                                    onClick: (e, datos) => {
                                        setModalEditar((s) => ({
                                            ...s,
                                            estado: !s.estado,
                                            datos
                                        }))
                                    }
                                }}
                                totalField={'NR'} /* NR = número de resultados totales */
                                path={'./rt_mesa_registro'}
                                refresh={esRefresh}
                                body={filtros}
                                rowsPerPage={20}
                                paginadorTop={true}
                            ></TablaPro>
                        </div>
                    </div>
                </div>
                <ModalEditarCarnet
                    modal={modalEditar}
                    setModal={setModalEditar}
                    setEsRefresh={setEsRefresh}
                />
                <ModalImpresion
                    modal={modalImpresion}
                    setModal={setModalImpresion}
                    setEsRefresh={setEsRefresh}
                    onImprimir={onImprimir}
                />
            </Fragment>
        </div>
    );
};

export default Default;
