import React, { Component, useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../common/loaderapp';
import axios from 'axios';

const SpeakersEdit = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [load, setLoad] = useState(true);
    const [speaker, setSpeaker] = useState({ speaker_name:'', company:'', bio:'', picture:''})
    const [key, setKey] = useState(0); 
    const [file, setFile] = useState(null);

    useEffect(() => {
        if(id != 0){
            fetch('/p_speaker', {
                method: 'PATCH',
                body: JSON.stringify({id}),
                headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then(res => {
				console.log('El speaker',res);
                if(res.length != 0){
                    const { ACTIVE_SPK,BIO_SPK,COMPANY_JOB_SPK,ID_EVE_SPK,ID_SPK,IS_VISIBLE_SPK,ORDER_SPK,PICTURE_SPK,REGISTER_DATE_SPK,SPEAKER_NAME_SPK } = res[0];
                    setSpeaker({ speaker_name:SPEAKER_NAME_SPK, company:COMPANY_JOB_SPK, bio:BIO_SPK, picture:PICTURE_SPK })
                }
            })
            .catch(err => console.log('Error: '+err))
        }
        setLoad(false);
    }, [])

    const { speaker_name, company, bio, picture } = speaker
    const actSpeaker=(e)=>setSpeaker({ ...speaker, [e.target.name]:(e.target.value)})
    
    function setInfo() {
        fetch('/p_speaker', {
            method: id != 0 ? 'PUT' : 'POST',
            body: JSON.stringify({...speaker,id}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            console.log('El speaker',res);
            navigate('/speakers')
        })
        .catch(err => console.log('Error: '+err))
    }

    const handleFileChange = (event) => {
        console.log('FILE',event.target.files[0]);
        const selectedFile = event.target.files[0];
        if (selectedFile && !/^image\//.test(selectedFile.type)) {
            console.error('Invalid file type. Only images are allowed.');
            setKey((prevKey) => prevKey + 1);
            setFile(null);
        } else {
            setSpeaker({ ...speaker, 'picture':selectedFile.name})
            setFile(selectedFile);
        }
    };

    const handleUpload = async () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            try {
                const response = await axios.post('/uploadSpeaker', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log('File uploaded successfully:', response.data);
                setInfo();
            } catch (error) {
            console.error('Error uploading file:', error);
            }
        } else {
            setInfo();
            console.error('No file selected');
        }
    };

    return (
        <Fragment>
            {load ? 
                <Loader/>: 
                <Fragment>
                    <div className="col-sm-12 mt-5 mb-5">
                        <div className="row">
                            <div className="col-sm-12 mb-2 mt-3">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <h5>Edit Speaker data</h5>
                                <p>You can edit the name, photo and title, also you can add new speakers or delete them.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-6 mb-3">
                                <label>Speaker name:</label>
                                <input className="form-control" name="speaker_name" value={speaker_name} onChange={actSpeaker}></input>
                            </div>
                            <div className="form-group col-md-6 mb-3">
                                <label>Company/Job:</label>
                                <input className="form-control" name="company" value={company} onChange={actSpeaker}></input>
                            </div>
                            <div className="form-group col-md-6 mb-3">
                                <label>Bio:</label>
                                <textarea className="form-control" name="bio" value={bio} onChange={actSpeaker}></textarea>
                            </div>
                            <div className="form-group col-md-6 mb-3">
                                <label className="form-label">Picture: (400x400px)</label>
                                <input key={key} className="form-control" type="file"  onChange={handleFileChange}></input>
                            </div>
                            <div className="col-12">
                                <button type="button" className="btn btn-primary" onClick={handleUpload}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    );
}

export default SpeakersEdit;