import {
    Home, FileText, Calendar, Users, Coffee, Grid, CreditCard, Gift, User
} from 'react-feather';



/* export const getMenu = () => {
    let menu = [];

    let permisos_grales = localStorage.getItem('auth');
    permisos_grales = JSON.parse(permisos_grales);
    
    permisos_grales = permisos_grales && permisos_grales.permisos ? permisos_grales.permisos : [];
    
    let permisos = permisos_grales.filter(permiso => permiso.ID_PADRE ? false: true);


    let index;

    permisos_grales.forEach(permiso => {
        if(permiso.ID_PADRE){
            index = permisos.findIndex(p => p.ID == permiso.ID_PADRE);

            if(index > -1){
                if(permisos[index].PERMISOS){
                    permisos[index].PERMISOS.push(permiso);
                } else {
                    permisos[index].PERMISOS = [permiso];
                }
            }
        }
    })

    console.log('permisos', permisos);
        
    
    let secciones = [], subsecciones = [];

    permisos.forEach(permiso => {
        if(permiso.PERMISOS){
            subsecciones = [];

            permiso.PERMISOS.forEach(subpermiso => {
                subsecciones.push({ path: subpermiso.RUTA, title: subpermiso.TITULO, type: 'link' });
            })

            secciones.push({
                title: permiso.TITULO, path: permiso.RUTA, icon: Users, type: 'link', active: false, children: subsecciones
            })
        } else {
            secciones.push({ title: permiso.TITULO, path: permiso.RUTA, icon: FileText, type: 'link', active: false })
        }
    })

    console.log('secciones', secciones);
    console.log('menu', menu);
    return secciones;
}; */


/* export const getMenu = () => {
    let menu = [];

    let permisos = localStorage.getItem('auth');
    permisos = JSON.parse(permisos);

    console.log('permisos', permisos);

    permisos = permisos ? permisos.permisos : null;

    let reportes = {
        title: 'REGISTROS', icon: Users, type: 'link', active: false, children: [

        ]
    };

    permisos.forEach((permiso) => {
        switch (permiso.NOMBRE) {
            case 'AGREGAR CARNET':              reportes.children.push({ path: '/carnet/nuevo', title: 'Nuevo Carnet', type: 'link' }); break;
            case 'REGISTROS GENERALES 2023':    reportes.children.push({ path: '/registros/3/0', title: 'Generales', type: 'link' }); break;
            case 'REGISTROS FULL ACCESS 2023':  reportes.children.push({ path: '/registros/3/1', title: 'Full-access', type: 'link' }); break;
            case 'REGISTROS TALLERES 2023':     reportes.children.push({ path: '/registros/3/2', title: 'Talleres 2023', type: 'link' }); break;
            case 'REGISTROS FULL ACCESS':       reportes.children.push({ path: '/registros/2/1', title: 'Evento', type: 'link' }); break;
            case 'REGISTROS TALLERES':          reportes.children.push({ path: '/registros/2/2', title: 'Talleres', type: 'link' }); break;
            case 'REGISTROS CENA':              reportes.children.push({ path: '/registros/2/4', title: 'Cena-show', type: 'link' }); break;
            case 'DASHBOARD':                   menu.push({ title: 'DASHBOARD', path: '/dashboard_graficas', icon: FileText, type: 'link', active: false }); break;
            case 'REPORTE DEPOSITOS':           menu.push({ title: 'REPORTE CLIP', path: '/depositos', icon: CreditCard, type: 'link', active: false }); break;
            case 'ALTA CORTESIAS':              menu.push({ title: 'CORTESIAS', path: '/cortesias', icon: FileText, type: 'link', active: false }); break;
            case 'ASIENTOS':                    menu.push({ title: 'ASIENTOS', path: '/asientos', icon: User, type: 'link', active: false }); break;
            case 'HOSPEDAJE HOTEL PRINCESS':    menu.push({ title: 'HOSPEDAJE', path: '/hospedaje', icon: Home, type: 'link', active: false }); break;
            case 'HOSPEDAJE HOTEL PALACIO':
                if (menu.findIndex(i => i.title === 'HOSPEDAJE') === -1) menu.push({
                    title: 'HOSPEDAJE', path: '/hospedaje', icon: Home, type: 'link', active: false
                });
                break;
        }
    })

    if (reportes.children.length > 0) {
        reportes.path = reportes.children[0].path;
        let primerItem = menu[0];
        menu[0] = reportes;
        menu.push(primerItem);
    }

    console.log('menu', menu);

    return menu;
}; */



export const getMenu=()=>  [
    /* {
        title: 'PUEBLA', path: '/registros/2/1', icon: FileText, type: 'link', active: false,children: [
            { path: '/registros/2/1', title: 'Evento', type: 'link' },
            { path: '/registros/2/2', title: 'Talleres', type: 'link' },
        ]
    }, */
    /* {
        title: 'President', path: '/president', icon: FileText, type: 'link', active: false
    },
    {
        title: 'Registration', path: '/registration', icon: FileText, type: 'link', active: false
    }, */
    /* {
        title: 'Program', path: '/program', icon: FileText, type: 'link', active: false
    }, */
    {
        title: 'Program', path: '/program', icon: FileText, type: 'link', active: false,children: [
            { path: '/program', title: 'Program', type: 'link' },
            { path: '/group', title: 'Group', type: 'link' },
        ]
    },
    {
        title: 'Speakers', path: '/speakers', icon: FileText, type: 'link', active: false
    },
    /* {
        title: 'Location', path: '/location', icon: FileText, type: 'link', active: false
    }, */
    {
        title: 'Info', path: '/info', icon: FileText, type: 'link', active: false
    },
    {
        title: 'Avisos', path: '/avisos', icon: FileText, type: 'link', active: false
    },
    /* {
        title: 'Dress Code', path: '/dress-code', icon: FileText, type: 'link', active: false
    },
    {
        title: 'Awards', path: '/awards', icon: FileText, type: 'link', active: false
    },
    {
        title: 'Anti-Trust Statement', path: '/anti-trust-statement', icon: FileText, type: 'link', active: false
    }, */
];
