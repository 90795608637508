import React, { useState, Fragment, useContext } from 'react';
import useForm from 'react-hook-form'
import logo from '../assets/images/endless-logo.png';
import notie from 'notie';

import { useNavigate } from "react-router-dom";
import SesionContexto from '../context/ContextoSesion';

import '../assets/css/notie.css';

const Signin = () => {

    const { sesion, setSesion } = useContext(SesionContexto);
    const history = useNavigate();
    const [step, setStep] = useState(0);
    const [user, setUser] = useState('');
    const { handleSubmit, register, errors } = useForm();

    const loginAuth = async (data) => {


        let response = await fetch('/p_login', {
            method: 'POST',
            body: JSON.stringify({ user: data.email, pass: data.password }),
            headers: { 'Content-Type': 'application/json' }
        });

        try {
            let res = await response.json()
            if (res.estado == 1) {
                setStep(1);
            }
            else {
                notie.alert({ type: 'error', text: `Invalid user or password, please verify your data.`, time: 2 });
            }
        }
        catch (error) {
            console.log('error loginAuth', error);
            notie.alert({ type: 'error', text: `Ocurrió un error en el servidor, favor de comunicarse con soporte técnico.`, time: 2 });
        }
    }

    const codeAuth = async (data) => {
        console.log('sesion de contexto', sesion);
        let response = await fetch('/p_code', {
            method: 'POST',
            body: JSON.stringify({ code: data.codigo, user: user }),
            headers: { 'Content-Type': 'application/json' }
        });
        try {
            let res = await response.json()
            if (res.estado == 1) {
                notie.alert({ type: 'success', text: `Welcome ${res.resultado.nombre} ${res.resultado.apellidos}`, time: 2 });
                localStorage.setItem('auth', JSON.stringify({
                    nombre: res.resultado.nombre,
                    apellidos: res.resultado.apellidos,
                    correo: res.resultado.correo,
                    permisos: res.resultado.permisos,
                }));

                setSesion({
                    nombre: res.resultado.nombre,
                    apellidos: res.resultado.apellidos,
                    correo: res.resultado.correo,
                    permisos: res.resultado.permisos,
                });

                setTimeout(() => {
                    console.log('sesion de contexto, después de login', sesion);
                }, 5000)

                history(`/inicio`);
            }
            else {
                notie.alert({ type: 'error', text: `Codigo incorrecto, favor de verificar sus datos.`, time: 2 });
            }
        }
        catch (err) {
            console.log('err', err);
            notie.alert({ type: 'error', text: `Ocurrió un error en el servidor, favor de comunicarse con soporte técnico.`, time: 2 });
        }

    }

    const onSubmit = data => {
        if (!step) {
            if (data !== '') {
                loginAuth(data);
            } else {
                errors.showMessages();
            }
        }
        else {
            if (data !== '') {
                codeAuth(data);
            } else {
                errors.showMessages();
            }
        }
    };


    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center">
                                            <img src={logo} width="200" alt="" /></div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                {!step ?
                                                    <Fragment>
                                                        <div className="text-center">
                                                            <h6>Log in</h6>
                                                        </div>
                                                        <form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                                            <div className="form-group">
                                                                <label className="col-form-label pt-0">User</label>
                                                                <input className="form-control" type="text" name="email"
                                                                    ref={register({ required: "Required" })}
                                                                    placeholder="email@dominio.com"
                                                                    value={user}
                                                                    onChange={(e) => setUser(e.target.value)}
                                                                />
                                                                <p className="text-danger">{errors.email && 'Enter your user'}</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="col-form-label">Password</label>
                                                                <input className="form-control" type="password" name="password"
                                                                    placeholder="*******"
                                                                    ref={register({ required: "Required" })} />
                                                                <p className="text-danger">{errors.password && 'Enter your password'}</p>
                                                            </div>
                                                            <div className="form-group form-row mt-3 mb-0">
                                                                <button className="btn btn-primary btn-block" type="submit" >LOG IN</button>
                                                            </div>
                                                        </form>
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <div className="text-center">
                                                            <h6>We sent you a code </h6>
                                                            <p className="text-muted" >To continue please write the code we sent to your e-mail.</p>
                                                        </div>
                                                        <form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                                            <div className="form-group">
                                                                <input className="form-control text-center" type="text" name="codigo"
                                                                    ref={register({ required: "Required" })}
                                                                />
                                                                <p className="text-danger">{errors.codigo && 'Enter the correct code'}</p>
                                                            </div>

                                                            <div className="form-group form-row mt-3 mb-0">
                                                                <button className="btn btn-primary btn-block" type="submit" >CONTINUE</button>
                                                            </div>
                                                        </form>
                                                    </Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default Signin;