import React, { Fragment, useState, useEffect } from 'react';
import tools from '../../js/tools'

const Default = (props) => {
  const objectHeader = {
    name: '',
    clave: '',
    visible: true
  }

  const _props = {
    ...props,
    // rowOnClick: tools.isNull(props.rowOnClick)? (e) => props.rowOnClick({...e, row: v }): null
  }

  const getArrHeader = (header) => {
    return header.map((v, i) => ({
      ...v,
      width: !tools.isNull(v.width) ? v.width : `${100 / header.length}%`,
      higth: !tools.isNull(v.higth) ? v.higth : `${100 / header.length}%`,
      click: !tools.isNull(v.click) ? v.click : null,
      render: (v.render) ? v.render : null,
      type: !tools.isNull(v.type) ? v.type : 'text',
      styleHeader: !tools.isNull(v.styleHeader) ? v.styleHeader : {},
      styleColumn: !tools.isNull(v.styleColumn) ? v.styleColumn : {},
      indix: !tools.isNull(v.index) ? v.index : i + 1
    })).sort((a, b) => (a.index - b.index))
  }

  let _header = getArrHeader(props.header)

  // const [headerJson, setJson] = useState(props.header? tools.groupByJson(_header, 'index'): [])
  const [header, setHeader] = useState(props.header ? _header : [])
  const [datos, setDatos] = useState([])
  const [datosPag, setDatosPag] = useState({ atr: 1, sig: 1, nums: [], datos: [] })
  const [paginador, setPaginador] = useState({});
  const [datosCheck, setDatosCheck] = useState({});
  const [textoBuscar, setTextoBuscar] = useState('');
  
  const getDatosReal = () => {
    let _datos = props.datos !== undefined && props.datos !== null ? (props.datos) : []
    let arr = [], item = [], p = Math.ceil(_datos.length / props.sizePaginas), sizeP = props.sizePaginas

    if (_datos.length <= 0) {
      return
    }

    if (!props.isPaginador) {
      return
    }

    _datos.map((v, i) => {
      if (i < sizeP) {
        item.push(v)

        if (i === _datos.length - 1) {
          arr.push(item)
        }
      } else {
        sizeP += props.sizePaginas
        arr.push(item)
        item = []
        item.push(v)

        if (i === _datos.length - 1) {
          arr.push(item)
          item = []
        }
      }
    })

    setDatosPag({ i: 0, atr: 1, sig: arr.length > 1 ? 2 : 1, nums: arr.map((v, i) => (i + 1)), datos: arr, size: arr.length })
    setDatos(arr[0])
  }

  useEffect(() => {
    let isSuscribed = true

    if (isSuscribed) {
      setHeader(_header)
      // setJson(tools.groupByJson(_header, 'index'))
      if (props.esEstatic) {
        getDatosReal()
      } else {
        tools.useFetch({
          ruta: props.ruta,
          method: 'POST',
          body: {
            p: paginador.p,
            b: paginador.b,
            ...props.params
          }
        }, (response) => {
          if (isSuscribed) {
            setDatos(response.datos)
            setPaginador(response.paginador)
            setDatosCheck({})
            setTextoBuscar('')
          }
        })
      }
    }
    return () => isSuscribed = false
    // props.header, props.params
  }, [props.refresh]);

  const onSigPag = (pag) => {
    // setLoading(true)

    tools.useFetch({
      ruta: props.ruta,
      method: 'POST',
      body: {
        ...props.params,
        p: pag.p,
        b: pag.b,
        // ...filtros,
        // esEditDelete
      }
    }, (response) => {
      //   setLoading(false)
      if (!response.estado) {
        return
      }

      setDatos(response.datos ? response.datos : [])
      setPaginador(response.paginador)
    })
  }

  return (
    <Fragment>
      <div className='row'>
        <div className='col-md-12'>
          <div className='d-flex justify-content-between mb-2'>
            <div className='ml-4'>
              {/* {props.isPaginador?<Paginador datosPag={datosPag} setDatosPag={setDatosPag} setDatos={setDatos}/>:''} */}
              <div className="input-group mb-3">
                {props.isPaginador ? <PaginadorA paginador={paginador} onSigPag={onSigPag} /> : ''}
                <input type="text" className="form-control ml-2" placeholder="Buscar" aria-label="" aria-describedby="basic-addon1" style={{width: 600}}
                  value={textoBuscar}
                  onChange={(v) => {
                    v.persist();
                    let textSearch = v.target.value
                    setTextoBuscar(textSearch)
                  }}
                  onKeyUp={(v) => {
                    v.persist();
                    let textSearch = v.target.value
              
                    // if(textSearch){
                      setTextoBuscar(textSearch)
                    // }
                    // if (v.nativeEvent.inputType === "deleteContentBackward" && textSearch.length < 3) {
                    //   return onSigPag({ p: 0, b: '' })
                    // }
                    if (textSearch.length === 0) {
                      onSigPag({ p: 0, b: '' })
                    }

                    // if (textSearch.length === 3) {
                    //   onSigPag({ p: 0, b: '' })
                    // }

                    if (textSearch.length > 3) {
                      onSigPag({ p: 0, b: textSearch })
                    }
                  }} />
                  {props.esFiltro? <button type="button" className="btn btn-success ml-3"
                      onClick={() => {
                        onSigPag({})
                        setTextoBuscar('')
                      }}
                  ><i className="fa fa-refresh"></i> Limpiar Filtro</button>: ''}
                {Object.keys(datosCheck).length ? <input disabled={true} type="text" className="form-control ml-2" placeholder="" aria-label="" aria-describedby="basic-addon1" onChange={() => null} value={` Selección: ${Object.keys(datosCheck).length}`} /> : ''}
              </div>
            </div>
            <div>
              {props.html ? props.html() : ''}
            </div>
          </div>
        </div>
        <div className='col-md-12'>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {/* <th scope="col">#</th> */}
                  {header.map((h, i) => {
                    return (<th
                      key={`${i}-${h.name}`}
                      style={{ ...h.styleHeader, width: h.width }}
                      scope="col">{h.name}</th>)
                  })}
                </tr>
              </thead>
              <tbody>
                {/* <th scope="row">1</th> */}
                {datos.length ? datos.map((v, i) => {
                  return (<tr key={`${i}`} onClick={!tools.isNull(props.rowOnClick) ? (e) => props.rowOnClick({ ...e, row: v }) : null}>
                    {header.map((h, l) => {
                      return <td
                        key={`${i}-${l}`}
                        style={{ ...h.styleColumn, width: h.width }}
                        onClick={h.click}>
                        {h.type === 'check' ?
                          <label className="d-block" htmlFor="chk-ani">
                            <input className="checkbox_animated" id="chk-ani" type="checkbox"
                              checked={datosCheck[v[props.clave]] ? true : false}
                              onChange={(c) => {
                                if (props.clave) {
                                  if (datosCheck[v[props.clave]]) {
                                    let _datosCheck = Object.assign({}, datosCheck)
                                    delete _datosCheck[v[props.clave]]
                                    if (props.onClickCheck) {
                                      props.onClickCheck(_datosCheck)
                                    }
                                    setDatosCheck(_datosCheck)
                                  } else {
                                    let _datosCheck = Object.assign({}, datosCheck)
                                    _datosCheck[v[props.clave]] = v
                                    setDatosCheck(_datosCheck)
                                    if (props.onClickCheck) {
                                      props.onClickCheck(_datosCheck)
                                    }
                                  }
                                }
                              }}
                            />
                          </label>
                          : ''}
                        {h.type === 'radio' ?
                          <label className="d-block" htmlFor="chk-ani">
                            <input className="checkbox_animated" id="chk-ani" type="checkbox"
                              checked={datosCheck[v[props.clave]] ? true : false}
                              onChange={(c) => {
                                if (props.clave) {
                                  if (Object.keys(datosCheck).length) {
                                    let _datosCheck = {}
                                    _datosCheck[v[props.clave]] = v
                                    if (props.onClickCheck) {
                                      props.onClickCheck(_datosCheck)
                                    }
                                    setDatosCheck(_datosCheck)
                                  } else {
                                    let _datosCheck = Object.assign({}, datosCheck)
                                    _datosCheck[v[props.clave]] = v
                                    setDatosCheck(_datosCheck)
                                    if (props.onClickCheck) {
                                      props.onClickCheck(_datosCheck)
                                    }
                                  }
                                }
                              }}
                            />
                          </label>
                          : ''}
                        {h.type === 'text' ?
                          (h.render ? h.render(v) : v[h.clave])
                          : ''}
                      </td>
                    })}
                  </tr>)
                }) : <tr>
                  <td align='center' colSpan={header.length}><div className="d-flex justify-content-center">Sin Registros</div></td>
                </tr>}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const PaginadorA = (props) => {
  return (<nav aria-label="Page navigation example">
    <ul className="pagination">
      <li className="page-item">
        <a className="page-link" href="#" aria-label="Previous" onClick={(e) => {
          e.preventDefault()
          props.onSigPag({ p: props.paginador.atr, b: props.paginador.b, })
        }}>
          <span aria-hidden="true">&laquo;</span>
          <span className="sr-only">{'<'}</span>
        </a>
      </li>
      {props.paginador.e1 ?
        <Fragment>
          <li className="page-item"><a className="page-link" href="#"
            onClick={(e) => {
              e.preventDefault()
              props.onSigPag({ p: props.paginador.primero, b: props.paginador.b, })
            }}
          >1</a></li>
          <li className="page-item"><a className="page-link" href="#"
            onClick={(e) => {
              e.preventDefault()
              props.onSigPag({ p: props.paginador.primero, b: props.paginador.b, })
            }}
          >...</a></li>
        </Fragment>
        : ''}
      {tools.getPaginador(props.paginador).map((v, i) => {
        if (props.paginador.p === v.i) {
          return <li key={`${v.i}-${i}`} className="page-item active"><a className="page-link" style={{zIndex: 0}} href="#"
            onClick={(e) => {
              e.preventDefault()
              props.onSigPag({ p: v.i, b: props.paginador.b })
            }}
          >{v.p}</a></li>
        } else {
          return <li key={`${v.i}-${i}`}
            className="page-item"><a className="page-link" style={{zIndex: 0}} href="#"
              onClick={(e) => {
                e.preventDefault()
                props.onSigPag({ p: v.i, b: props.paginador.b })
              }}
            >{v.p}</a></li>
        }
      })}
      {props.paginador.e2 ?
        <Fragment>
          <li className="page-item"><a className="page-link" href="#" onClick={(e) => {
            e.preventDefault()
            props.onSigPag({ p: props.paginador.u, b: props.paginador.b })
          }}>...</a></li>
          <li className="page-item"><a className="page-link" href="#"
            onClick={(e) => {
              e.preventDefault()
              props.onSigPag({ p: props.paginador.u, b: props.paginador.b })
            }}
          >{props.paginador.ultimo}</a></li>
        </Fragment>
        : ''}

      <li className="page-item">
        <a className="page-link" href="#" aria-label="Next"
          onClick={(e) => {
            e.preventDefault()
            props.onSigPag({ p: props.paginador.sig, b: props.paginador.b })
          }}
        >
          <span aria-hidden="true">&raquo;</span>
          <span className="sr-only">{'>'}</span>
        </a>
      </li>
    </ul>
  </nav>
  )
}

const Paginador = (props) => {
  // const newNum = (nums, datosPag) => {
  //     if(datosPag.size <= 3){
  //         return nums.slice(0, 3)
  //     }

  //     if(datosPag.size > 3){
  //         let x = 0, y = 3

  //         if(datosPag.i datosPag.size){
  //             x = 1
  //             y = 0
  //         }

  //         if(datosPag.i){

  //         }

  //         return nums.slice(x, y)
  //     }

  //     return nums.slice(0, 3)
  // }

  return (<nav aria-label="Page navigation example">
    <ul className="pagination justify-content-center">
      <li className="page-item">
        <a className="page-link" href="#" tabIndex="-1" onClick={(e) => {
          e.preventDefault()
          if (props.datosPag.i > 0) {
            props.setDatos(props.datosPag.datos[props.datosPag.i - 1])
            props.setDatosPag((k) => ({ ...k, i: k.i - 1 }))
          }
        }}>{'<'}</a>
      </li>
      {/*setDatosPag({atr: 1, sig: arr.length > 1? 2: 1, nums: arr.map((v,i) => (i + 1)), datos: arr}) */}
      {props.datosPag.nums.map((v, i) => {
        return (<li
          className={`page-item ${i === props.datosPag.i ? 'active' : ''}`}
          key={`${v}-${i}`}>
          <a className="page-link" href="#" onClick={(e) => {
            e.preventDefault()
            props.setDatosPag((k) => ({ ...k, i: i }))
            props.setDatos(props.datosPag.datos[i])
          }}>{v}</a>
        </li>)
      })}
      {/*<li class="page-item"><a class="page-link" href="#">1</a></li>*/}
      <li className="page-item">
        <a className="page-link" href="#" onClick={(e) => {
          e.preventDefault()
          if (props.datosPag.i < props.datosPag.size - 1) {
            props.setDatos(props.datosPag.datos[props.datosPag.i + 1])
            props.setDatosPag((k) => ({ ...k, i: props.datosPag.i === props.datosPag.size - 1 ? k.i : k.i + 1 }))
          }
        }}>{'>'}</a>
      </li>
    </ul>
  </nav>)
}

export default Default;