import React, { Fragment, useState, useEffect, Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../common/loaderapp';
import axios from 'axios';

const AvisoEdit = () => {
    let navigate = useNavigate();
    const { id } = useParams();

    const [load, setLoad] = useState(true)
    const [aviso, setAviso] = useState({title:'',subtitle:'',description:''})
    const [key, setKey] = useState(0); 
    const [files, setFiles] = useState(null);

    const {title,subtitle,description} = aviso

    useEffect(() => {
        console.log('el id', id);
        if(id != 0){
            fetch('/p_avisos', {
                method: 'PATCH',
                body: JSON.stringify({id}),
                headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then(res => {
				console.log('El INFO',res);
                if(res.length != 0){
                    const { ID_AN,TITULO_AN,SUBTITULO_AN,DESCRIPCION_AN,FECHA_ALTA_AN,ACTIVO_AN } = res[0];
                    setAviso({title:TITULO_AN,subtitle:SUBTITULO_AN,description:DESCRIPCION_AN})
                }
            })
            .catch(err => console.log('Error: '+err))
        }
        setLoad(false);
    }, [])

    function setAvisoPractical() {
        fetch('/p_avisos', {
            method: id != 0 ? 'PUT' : 'POST',
            body: JSON.stringify({...aviso,id}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            console.log('El info',res);
            navigate('/avisos')
        })
        .catch(err => console.log('Error: '+err))
    }

    const actInfo=(e)=>setAviso({ ...aviso, [e.target.name]:(e.target.value)})
    const actCheck=(e)=>setAviso({ ...aviso, [e.target.name]:(e.target.checked)})

    return (
        <Fragment>
        {
            load ? 
            <Loader/> :
            <Fragment>
                <div className="col-sm-12 mt-5 mb-5">
                    <div className="row">
                        <div className="col-sm-12 mb-2 mt-3">
                            {/* <Breadcrumb title="President" parent="Inicio" /> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h5>Edit information or Add new information</h5>
                            {/* <p>You can edit the title and content, also you cand add a link or a file to download.</p> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-4">
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6 mb-3">
                            <label>Title:</label>
                            <input className="form-control" name='title' value={title} onChange={actInfo} disabled={id != 0 ? true : false}></input>
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <label>Subtitle:</label>
                            <input className="form-control" name='subtitle' value={subtitle} onChange={actInfo} disabled={id != 0 ? true : false}></input>
                        </div>
                        <div className="form-group col-md-12 mb-3">
                            <label>Description:</label>
                            <textarea className="form-control" value={description} onChange={actInfo} name='description'></textarea>
                        </div>

                        <div className="col-12">
                            <button type="button" className="btn btn-primary" onClick={setAvisoPractical}>Save changes</button>
                        </div>
                    </div>
                </div>
            </Fragment >
        }
        </Fragment>
    );
}

export default AvisoEdit;